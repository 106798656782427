<template>
    <div tabindex="1">
        <b-icon icon="info-circle-fill" style="width: 17px; height: 17px;: center; cursor: pointer" :style="cDescription == null || cDescription.length == 0 ? 'color: #8898aa' : 'color: #066791' " :id="'popover_' + _uid.toString() + id.toString()" @click="openPopover"></b-icon>

        <b-popover v-if="popover_show" id="popover" style="width: 300px" :show.sync="popover_show" :target="popover_target" triggers="focus" no-fade placement="top" @hide="popoverHide" boundary="window">
            <template #title>
                {{trans('sh-description-label',314)}}
            </template>
            <div class="my-1">
                <app-memo ref="textarea" id="textarea" rows="3" v-model="cDescription" :lettersCounter="false" :maxLength="500" style="min-width:220px"/>          
            </div>

        </b-popover>

    </div>
</template>
<script>

export default {

    components: {

    },

    props: {
            description: { type: String }, 
            id: {type: Number},       
    },

    created() {
    },

    computed: {
        
        cDescription: {
            get() { return this.description },
            set(value) {
                this.$emit('update:description', value);
            }
        },
    },

    data() {
        return {
            popover_show: false,
            popover_target: null,
            popover_user: null,
        };
    },

    methods: {
        async openPopover(){
            if(this.popover_show == false){
                this.popover_user = true;
                this.popover_target = 'popover_' + this._uid.toString() + this.id.toString();
                this.popover_show = true;
                await this.$nextTick();
                document.getElementById('textarea').focus()
                
            }
        },

        popoverHide(){
            this.popover_user = null;
            this.popover_target = null;
            this.popover_show = false;
        },

        onClose(){
            this.popover_show = false;
        }
        
    }

    };
</script>

<style scoped>
 .popover{
            max-width: 300px !important;
        }


</style>