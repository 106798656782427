<template>
    <div>
         <b-card>
             <b-row class="d-flex align-items-center mb-3 app-height-min-32">
                 <b-col start>
                    <div class="app-card-profile-title">{{trans('sh-map-imported-fields',314)}}</div>
                 </b-col>
                 <b-col cols="auto" class="p-0" v-if="cProcedure == '0'">
                    <app-select class="app-width-min-200" v-model="cData.selected_schema" :clearable="true" :selectOnTab="true" :options.sync="schemas_data" @input="setSchema" :disable="schemas_data.length == 0 || cProcedure != '0'" :key="refresh_1" /> 
                 </b-col>
                <b-col cols="auto" end v-if="cProcedure == '0'">
                    <b-dropdown variant="link" no-caret right toggle-class="pl-0 pr-0 m-0" :disabled="(cSchemaOptions || schemas_data.length == 0) && !cSchemaOptionsAdd" v-if="!loading">
                        <template #button-content>
                            <b-icon icon="three-dots-vertical" class="text-body"></b-icon>
                        </template>
                        <b-dropdown-item v-if="cSchemaOptionsAdd" @click="addSchema">{{trans('sh-add-schedule',314)}}</b-dropdown-item>
                        <b-dropdown-item v-if="cSchemaOptionsChange && schemas_data.length > 0" @click="changeSchema(data.selected_schema)">{{trans('sh-change-schedule',314)}} </b-dropdown-item>
                        <b-dropdown-divider v-if="cSchemaOptionsChange && schemas_data.length > 0"></b-dropdown-divider>
                        <b-dropdown-item v-if="cSchemaOptionsChange && schemas_data.length > 0" @click="removeSchema(data.selected_schema)">{{trans('sh-delete-schedule',314)}}</b-dropdown-item>
                    </b-dropdown>
                    
                    <b-spinner style="width:19px; height:19px; margin-left:3px; margin-top:5px" small v-if="loading"></b-spinner>
                    
                 </b-col>
            </b-row>

            <div v-if="!loading">
        
                <b-row class="ml-1 mr-1 pb-2 app-local-row-header-font">
                    <b-col class="pl-1">
                        {{trans('sh-set-column',314)}}
                    </b-col>
                    <b-col>
                        {{trans('sh-service-of',314, {column_page : column_page, length : cImportData.length})}}
                    </b-col>
                </b-row>
                <b-list-group flush class="app-local-bottom-line" v-if="!loading">
                    
                    <b-list-group-item class="p-2 ml-2 mr-2" v-for="item in cData.columns.items" :key="item.index">
                        <b-row align-v="center" class="pl-2">
                            <b-col class="p-0">
                                <app-select :clearable="true" customBody v-model="item.type" :selectOnTab="true" :options="column_types" :selectable="setColumnTypeLimit" :disable="cProcedure == '1'">
                                    <template v-slot:option="option">
                                        <div style="border-top:1px solid" v-if="option.data.value <= 0"></div>
                                        {{ option.data.name }}
                                    </template>
                                </app-select>
                            </b-col>
                            <b-col>
                                <span class="pl-1 app-local-row-font"> {{ cImportData[column_page-1][item.index] }} </span>
                            </b-col>
                        </b-row>
                    </b-list-group-item>

                </b-list-group>
                <small v-if="cData.errors == 2" class="ml-1 text-danger app-input-error-ellips" data-toggle="tooltip">{{trans('sh-not-all-columns-selected',314)}}</small> 
                <b-row class="d-flex align-items-center mt-3 app-height-min-32" >
                    <b-col cols="auto" start v-if="cProcedure == '0'">
                        <b-form-checkbox v-model="cData.columns.headers" class="custom-control-primary" size="lg" @input="column_page = 1">
                            {{trans('sh-column-headers-in-file',314)}}
                        </b-form-checkbox>               
                    </b-col>
                    <b-col>
                            <b-pagination first-number last-number pills align="right" v-model="column_page" :total-rows="cImportData.length" :per-page="1" size="sm"/>
                    </b-col>
                </b-row>
                
                <div v-if="cData.validation_configuration" class="mt-2"> 
                    <small class="text-danger" v-if="data.import.level < 2">{{trans('sh-set-dates-funtions-time-department',314)}}</small>        
                </div> 
            </div>
         </b-card>
         <div id="divHtml" style="display:none; visibility: hidden; height: 5px"></div>
         <offers-shifts-add-bulk-schema-add v-if="mSchemaAdd.show" :show.sync="mSchemaAdd.show" :args.sync="mSchemaAdd.args" :result.sync="mSchemaAdd.result" :return.sync="schemas_data" />
         <offers-shifts-add-bulk-schema-change v-if="mSchemaChange.show" :show.sync="mSchemaChange.show" :args.sync="mSchemaChange.args" :result.sync="mSchemaChange.result" :return.sync="schemas_data" />
    </div>
</template>
<script>

import axios from "@axios";
import AppAlerts from '@core/scripts/AppAlerts';
import OffersShiftsAddBulkSchemaAdd from './Schema/OffersShiftsAddBulkSchemaAdd.vue';
import OffersShiftsAddBulkSchemaChange from './Schema/OffersShiftsAddBulkSchemaChange.vue';


export default {
    components: {
        OffersShiftsAddBulkSchemaAdd,
        OffersShiftsAddBulkSchemaChange
    },

    props: {
        data: {},
        configData: {}
    },

    computed: {
        cData: {
            get() {                     
                return this.data;
            },
            set(value) { this.$emit('update:data', value) }
        },

        cConfigData: {
            get() { return this.configData },
            set(value) { this.$emit('update:configData', value) }
        },

        cIdClientDataMain : {
            get() {
                if (this.data.import.level == 0 && this.data.client != null) return this.data.client.id_client_data_main;
                else return null;
            }
        },

        cProcedure : {
            get() {
                if (this.data.import.level == 0 && this.data.client != null) return this.data.client.import_procedure;
                else return null;
            }
        },

         cImportData: {
            get() { 
                if (this.data.columns.headers) {
                    return this.data.import.items.slice(1); 
                } else {
                    return this.data.import.items; 
                } 
            },
        },
        cSchemaOptions: {
            get(){
                var isNull = true;
                if (this.data.columns.items.filter(element => element.type != null).length > 0) {
                    isNull = false;
                }

                if (this.cData.selected_schema != null) {
                    isNull = false;
                }
                    
                return isNull;
            }
        },

        cSchemaOptionsAdd: {
            get(){
                var isNull = false;
                if (this.data.columns.items.filter(element => element.type != null).length > 0) {
                    isNull = true;
                }
                    
                return isNull;
            }
        },

        cSchemaOptionsChange: {
            get(){
                var isNull = false;
                if (this.data.selected_schema != null) {
                    isNull = true;
                }
                    
                return isNull;
            }
        },

    },

    created(){
        this.clearSchema();
        this.schemas_data = this.cConfigData.schemas;
        if(this.cConfigData.schemas.length > 0 && this.data.last_schema.length == 0) {                            
            this.setSchema(this.cConfigData.schemas[0])
            this.refresh_1++;
        }else{
            this.setSchema(this.data.last_schema)
            this.refresh_1++;
        }
        this.prepareColumns();

    },

    data() {
        return {      
            loading: false,
            column_page: 1,
            schemas_data: [],
            original_schema:[],
            column_types: [
                { value: 1, name: this.trans('department',280) },
                { value: 0, name: ""},
                { value: 2, name: this.trans('sh-function',292) },
                { value: 11, name: this.trans('flexworker',280) },
                { value: -1, name: ""},
                { value: 4, name: this.trans('date-start',283) },
                { value: 5, name: this.trans('sh-start-date-and-time',314) },
                { value: 6, name: this.trans('planning-start-time',314) },
                { value: 7, name: this.trans('time-to',314) },
                { value: 8, name: this.trans('sh-begin-and-end-time',314) },
                { value: 16, name: this.trans('planning-employment',288)},
                { value: 15, name: this.trans('sh-type-of-service', 292)},
                { value: 9, name: this.trans('sh-enddate-and-time',314) },
                { value: -2, name: ""},
                { value: 10, name: this.trans('sh-description-label',314)},
                { value: 12, name: this.trans('h-pause',305)},
                { value: 13, name: this.trans('sh-shiftcode',314)},
                { value: 14, name: this.trans('kwalifications', 283)},
                { value: 17, name: this.trans('pl-applicant-type', 314)}
                
            ],
            mSchemaAdd: { show: false, args: { id_client_data_main:null, columns: null, with_headers: null, id_office_data_location:null, basic_informations:null, schema_name:null}, result: 0, return: null },
            mSchemaChange: { show: false, args: { id_shift_data_import: null, id_client_data_main:null,  schema_name: null,  columns: null, with_headers: null, id_office_data_location:null, basic_informations:null}, result: 0, return: null },
            alert_class: new AppAlerts(),
            refresh_1: 0,
            schema_name: null,
        };
    },


    watch: {
        "mSchemaChange.result": {
            handler: function(val){
                if(val > 0){
                    this.cConfigData.basic_informations = this.mSchemaChange.args.basic_informations;
                    this.schema_name = this.mSchemaChange.args.schema_name;
                    this.loadSchema(this.cIdClientDataMain)
                    this.mSchemaChange.result = 0;
                }
            },
            deep: true
        }, 
       
        "mSchemaAdd.result": {
            handler: function(val){
                if(val > 0){
                    this.cConfigData.basic_informations = this.mSchemaAdd.args.basic_informations;
                    this.schema_name = this.mSchemaAdd.args.schema_name;
                    this.loadSchema(this.cIdClientDataMain)
                    this.mSchemaAdd.result = 0;
                }
            },
            deep: true
        },

        "data.columns.refresh": {
            handler: function(val){
                if(val > 0){
                   this.setSchema(this.cData.selected_schema)
                }
            }
        },

        "cConfigData.schemas": {
            handler: function(val){
                this.clearSchema();
                this.schemas_data = this.cConfigData.schemas;
                if(this.data.columns.items.length > 0 && this.schemas_data.length > 0 && !this.schema_name) {                            
                    this.setSchema(this.schemas_data[0])
                    this.refresh_1++;
                }else if(this.schema_name){
                    for(var i=0;i<this.schemas_data.length;i++){
                        if(this.schema_name == this.schemas_data[i].name){
                            this.setSchema(this.schemas_data[i])
                            this.refresh_1++;
                            return;
                        }
                    }
                }
            },
            deep: true,
        },
       
    },

    methods: {

        prepareColumns() {
            let columns_count = this.getColoumnsCount();
            this.cData.columns.items = [];
            for(var i=0; i < columns_count; i++) {
                this.cData.columns.items.push({type: null, index: i});
                this.original_schema.push({type: null, index: i});
            }

            if (this.data.selected_schema != null) {
                this.setSchema(this.data.selected_schema);
            }
        },

        getColoumnsCount() { 
            let rows = this.cData.import.items.length;
            let columns_count = 0;

            if (rows > 10) {
                rows = 10;
            }

            for(var i=0; i < rows; i++) {                
                if (columns_count < this.cData.import.items[i].length) {
                    columns_count = this.cData.import.items[i].length;
                }
            }

            return columns_count;
        },

        addSchema() {
            this.mSchemaAdd.args.with_headers = this.cData.columns.headers;
            this.mSchemaAdd.args.columns = JSON.stringify(this.cData.columns.items);
            this.mSchemaAdd.args.id_client_data_main = this.cIdClientDataMain;
            this.mSchemaAdd.args.id_office_data_location = this.cData.office_location.value;
            this.mSchemaAdd.show = true;
        },

        setSchema(item) { 
            this.cData.selected_schema = item;           

            if (item != null && item.import_schema != null) {

                this.cData.columns.headers = item.with_headers;
                let schema = JSON.parse(item.import_schema);
                
                for(let i = 0; i < this.cData.columns.items.length; i++) {
                    if (schema[i] !== undefined && this.original_schema[i] !== undefined) {
                        this.cData.columns.items[i].type = schema[i].type;
                        this.original_schema[i].type = schema[i].type;
                    }
                }

            } else {

                this.cData.columns.headers = false;
                for(let i = 0; i < this.cData.columns.items.length; i++) {
                    this.cData.columns.items[i].type = null;
                }

            }
            
        },

        clearSchema(){
            this.cData.selected_schema = null;
            for(let i = 0; i < this.cData.columns.items.length; i++) {
                this.cData.columns.items[i].type = null;
            }
        },

        changeSchema(item){
            this.mSchemaChange.args.with_headers = this.cData.columns.headers;
            this.mSchemaChange.args.schema_name = item.name;
            this.mSchemaChange.args.id_shift_data_import = item.value;
            this.mSchemaChange.args.columns = JSON.stringify(this.cData.columns.items);
            this.mSchemaChange.args.id_client_data_main = this.cIdClientDataMain;
            this.mSchemaChange.args.id_office_data_location = this.cData.office_location.value;
            this.mSchemaChange.result = 0;
            this.mSchemaChange.show = true;
        
        },

        removeSchema(item){
            var id_client_data_main;
            id_client_data_main = this.data.client.id_client_data_main;
            
            this.alert_class.openAlertConfirmation(this.trans('sh-delete-schedule-confirmation',314)).then(res => {
                if(res == true){
                    this.loading = true;
                    axios
                        .post('shifts/removeImportSchema',{
                            id_shift_data_import: item.value,
                            id_client_data_main: id_client_data_main,
                            id_office_data_location: this.cData.office_location.value,
                        })
                        .then((res) => {
                            this.cConfigData.basic_informations = res.data;
                            this.loadSchema(id_client_data_main)
                            this.setSchema(this.cConfigData.schemas[0])
                        })
                        .catch((error) => {
                            console.error(`error ${error}`);
                        })
                        .finally(() => {
                            this.clearSchema();
                            this.loading = false;
                        })

                }
            });

        },

        loadSchema(id_client_data_main){
            this.cConfigData.schemas = this.cConfigData.basic_informations
                                    .filter(x => x.id_client_data_main == id_client_data_main )
                                    .map((x)=>{
                                        var a = {};
                                        a.name = x.schema_name;
                                        a.value = x.schema_value;
                                        a.import_schema = x.import_schema;
                                        a.with_headers = x.with_headers;
                                        return a;
                                    });
            this.cConfigData.schemas = [...new Map(this.cConfigData.schemas.map(item =>
                                    [item['value'], item])).values()].filter(x => x.value != null);
            this.cConfigData.schemas.sort((a,b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0))
        },

        setColumnTypeLimit(option) {

            var types = [];
            this.data.columns.items.forEach(element => {
                if(element.type){
                    types.push(element.type.value);
                }
            });

            if(option.value > 0) {
                
                if (types.includes(option.value)) { return false; }
        
                if (option.value === 4 || option.value === 5) { //start date, start date and time
                    if (types.includes(4) || types.includes(5)) { return false; } else {return true;}
                }

                if (option.value === 6) { //start time
                    if (types.includes(5) || types.includes(8)) { return false; } else {return true;}
                }

                if (option.value === 7) { //end time
                    if (types.includes(8) || types.includes(9)) { return false; } else {return true;}
                }

                if (option.value === 8) { // start and end time
                    if (types.includes(5) || types.includes(6) || types.includes(7) || types.includes(9)) { return false; } else {return true;}
                }

                if(option.value === 9){// end datum and time
                    if (types.includes(7) || types.includes(8) || types.includes(9)) { return false; } else {return true;}
                }

                return true;
                
            } else {
                return false;
            }
        },
    }
   
};
</script>
<style scoped>

.image-upload-wrap {
    border: 1px dashed #BBE7FA;
    border-radius: 5px;
    position: relative;
    transition: 1s;
}


.image-dropping,
.image-upload-wrap:hover {
    background-color: #f8fcfe;
}

.file-upload-input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
}

.drag-text {
    text-align: center;
    padding: 20px 10px;
}

.drag-text p {
    color: #000000;
    font-size: 12px;
}

.app-local-row-font {
    color: #174066;
    font-weight: 300;
    font-size: 14px;
}

.app-local-row-header-font {
    font-weight: 600;
    color: #1f627f;
    font-size: 14px;
    text-transform: none;
    letter-spacing: 1px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.05);
}

.app-local-bottom-line {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

</style>
