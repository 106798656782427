<template>
    <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('sh-add-schedule',314)">
        <ValidationObserver ref="form" >

            <app-row-left-label :label="trans('full-name',283)">
                <app-input v-model="schema_name" :validatorName="trans('full-name',283)" validatorRules="required" />
            </app-row-left-label>

        </ValidationObserver>

        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',282)}}</app-button><br>
            <app-button type="primary" :loading="loading" @click="add">{{trans('add',290)}}</app-button>
        </template>

    </b-modal>
</template>
<script>

import {ValidationObserver} from "vee-validate";
import axios from "@axios";

export default {

    components: {
        ValidationObserver
    },

    props: ["show", "args", "result", "return"],

    computed: {
        computedShow: {
            get() { return this.show },
            set(value) { this.$emit('update:show', value) }, 
        },
        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        },
        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },
    },

    data(){
        return{
            schema_name: null,
            loading: false
        }
    },

    methods: {

        add(){ 
           this.$refs.form.validate().then((result) => {
                if (!result) {            
                    return false;
                }

                this.loading = true;

                axios
                    .post('shifts/addSchema', {
                        name: this.schema_name,
                        id_client_data_main: this.args.id_client_data_main,
                        import_schema: this.args.columns,
                        with_headers: this.args.with_headers,
                        id_office_data_location: this.args.id_office_data_location,
                    })
                    .then((res) => {
                        this.cArgs.basic_informations = res.data;
                        this.cArgs.schema_name = this.schema_name;
                    })
                    .catch((error) => {
                        console.error(`error: ${error}`)
                    })
                    .finally(() =>{
                        this.$emit('update:result', 1);
                        this.loading = false;
                        this.computedShow = false;
                    });

           });

        },

        closeModal() {
            this.computedShow = false;
        },

    }
    
}
</script>
<style>

</style>