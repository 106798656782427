<template>
    <div>
        <b-modal ref="modal-add" size="xl" v-model="computedShow" no-close-on-backdrop :title="trans('flexworkers',283)">
            <b-card class="app-local-card-size">
                <app-view-table class="table-selectable" :title="trans('sh-flexworkers',292)" :loading="loading" :isTitleTotal="true" :filters.sync="filters" :items="items" v-on:onRowSelected="selectItem" :fields="fields" :isDropdownMenu="false" :isSearch="true" :customColumns="customColumns" :key="refresh" :isPerPageSelect="false">
                    <template v-slot:selected="cell">
                        <div class="h-100 d-flex align-items-center justify-content-center">
                            <b-form-group>
                                <!-- <b-form-checkbox v-model="cell.data.item.selected" class="custom-control-primary" style="margin-top:5px" size="md" @change="selectItem(cell.data.item, 1)"/> -->
                                <app-check-box v-model="cell.data.item.selected" @change="selectItem(cell.data.item, 1)"/>
                            </b-form-group>
                        </div>
                    </template>
                </app-view-table>
            </b-card>
            <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',282)}}</app-button>
            <app-button type="primary"  @click="changePerson" :disabled="id_person_data_main == null">
                {{trans('save',282)}}
            </app-button>
        </template>
        </b-modal>
    </div> 
</template>

<script>
import axios from "@axios";

export default {

    props: ["show", "result", "args", "return"],

    created(){
        this.getList();
    },

    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },

        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        },

        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        }

    },

    watch: {
        filters: {
            handler: function(val) {
                if (this.filters.sort_by != "") {
                    this.loading = true;
                    this.getList();
                }
            },
            deep: true,
        },
    },

    data() {
        return {
            is_switch: this.args.is_switch,
            applicant_type: this.args.applicant_type,
            id_person_data_main:this.args.id_person_data_main,
            person_name:this.args.person_name,
            avatar: this.args.avatar,
            id_function_data_person: this.args.id_function_data_person,
            function_name: this.args.person_function_name,
            person_type: this.args.person_type,
            sex: this.args.sex,
            id_office_data_person: this.args.id_office_data_person,
            customColumns: ["selected"],
            refresh: 0,
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                filtering: {
                        functions: {type: 'list', changed: 0, values: this.args.ids_function == null ? []: this.args.ids_function, function: "getFunctions", parameters: null, widthClass:'app-width-min-350'},
                        office_location: {type: 'list', changed: 0, values: [this.args.id_office_location], function: "getLocations", parameters: null, widthClass:'app-width-min-350'},
                        types: {type: 'list', changed: 0, values: [], function: "getApplicantTypes", parameters: null, widthClass:'app-width-min-250'},
                },
                page: 1,
                per_page: 10,
                search: "",
                list_type: 1,
                changed: 0,
            },
            fields: [
                { key: "selected", label: "", tdClass: "py-0", visible: true  },
                { key: "name", label: this.trans('full-name',283), thStyle: { "min-width": "80px", width: "100%" }, visible: true},
                { key: "person_type", label: this.trans('person-type',283), thStyle: { "min-width": "150px", "max-width": "150px" }, visible: true, filtering: true, filtering_name: "types" },
                { key: "function_name", label: this.trans('sh-function',292), thStyle: { "min-width": "180px", "max-width": "180px" }, visible: true,  filtering: true, filtering_name: "functions"},
                { key: "office_location_name", label: this.trans('d-office',287), thStyle: { "min-width": "180px", "max-width": "180px" }, visible: true,  filtering: true, filtering_name: "office_location"},  
            ],
            loading: false,
            items:[],
        };
    },


    methods: {

        selectItem(item, type = null) {      
            if(type == null){
                item = item[0];
            }

            if(type == null && item === undefined){
                 for (var i=0; i<this.items.list.length; i++) {
                    this.items.list[i].selected = "0";
                }

                this.is_switch = null;
                this.applicant_type = null;
                this.id_person_data_main = null;
                this.person_name = null;
                this.avatar = null;
                this.sex = null;
                this.person_type = null;
                this.function_name = null;
                this.id_function_data_person = null;
                this.id_office_data_person = null;

            } else{


            var index = this.items.list.indexOf(item);
            for (var i=0; i<this.items.list.length; i++) {
                if(i != index){
                    this.items.list[i].selected = "0";
                }else{
                    if(type == null)
                    {   
                        this.items.list[i].selected = "1";
                    }
                }
            }

            if(item.selected == "1"){
                this.is_switch = item.is_second_applicant;
                this.applicant_type = {name: item.applicant_name, value: item.applicant_type};
                this.id_person_data_main = item.id;
                this.person_name = item.name;
                this.avatar = item.avatar;
                this.sex = item.sex;
                this.person_type = item.person_type;
                this.function_name = item.function_name;
                this.id_function_data_person = item.id_function_data_main;
                this.id_office_data_person = item.id_office_data_location;
            } else {
                this.is_switch = null;
                this.applicant_type = null;
                this.id_person_data_main = null;
                this.person_name = null;
                this.avatar = null;
                this.sex = null;
                this.person_type = null;
                this.function_name = null;
                this.id_function_data_person = null;
                this.id_office_data_person = null;
            }
            
            this.$set(this.items, index, item);
            }
        },

       getList: function() {
            this.loading = true;
            axios
                .post("shifts/search", {
                    filters: JSON.stringify(this.filters),
                    id_cao_data_main: this.args.id_cao_data_main,
                    id_function_data_main: this.args.id_function_data_main
                })
                .then((res) => {
                    this.items = res.data.items;

                    if(this.cArgs.id_person_data_main != null){
                        var index = this.items.list.indexOf(this.items.list.find(item => item.id == this.cArgs.id_person_data_main));
                        if(index >= 0){
                            this.items.list[index].selected = "1";
                        }
                    }
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        changePerson() {
            if(this.cArgs.zzp_preferences == 1){
                this.cArgs.applicant_type = this.applicant_type;
                this.cArgs.is_switch = this.is_switch;
            }else{
                if((this.applicant_type.value == 3 || this.applicant_type.value == 4) && this.cArgs.zzp_preferences == 2){
                    this.cArgs.applicant_type = {name: this.applicant_type.value == 3 ? 'Student' : 'Doorleen', value: this.applicant_type.value};
                }else{
                    this.cArgs.applicant_type = {name: this.cArgs.zzp_preferences == 2 ? 'UZK' : 'ZZP', value: this.cArgs.zzp_preferences == 2 ? 1 : 2};
                }
                this.cArgs.is_switch = 0;
            }
            this.cArgs.id_person_data_main = this.id_person_data_main;
            this.cArgs.person_name = this.person_name;
            this.cArgs.avatar = this.avatar;
            this.cArgs.sex = this.sex;
            this.cArgs.person_function_name = this.function_name;
            this.cArgs.person_type = this.person_type;
            this.cArgs.id_function_data_person = this.id_function_data_person;
            this.cArgs.id_office_data_person = this.id_office_data_person;
            this.$emit('update:result', 1);
            this.$emit("update:show", false);
        },

        closeModal() {
            this.$emit("update:show", false);
        },

    },
};
</script>

<style scoped>
.app-local-card-size{
    /* min-height:550px !important;  */
    max-height: 550px !important;
}
.app-local-icon{
    width:40px !important;
}
.app-local-dropdown{
    width:65px
}
.app-local-list-group-custom-style{
    max-height:300px !important;
    overflow-y: auto;
}
.app-local-list-group-item-custom-style{
    cursor:pointer; 
    padding:4px 4px;
}
.input-group-text {
    border: none;
}
.ps{
    height: 350px;
}
.card-body {
    padding-top: 1.5rem;
}
</style>
