<template>
    <div>
        <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('deviant-funtions',314)">
            <b-card class="app-local-card-size">
                <div class="app-fixed-search d-flex align-items-center">
                    <div class="d-flex align-content-center justify-content-between w-100">
                        <b-input-group class="input-group-merge" >
                        
                            <b-input-group-prepend is-text class="app-local-icon">
                                <b-icon icon="search" class="text-muted" v-if="!loading"></b-icon>      
                                <b-spinner class="align-middle" small v-if="loading"></b-spinner>  
                            </b-input-group-prepend >

                            <b-form-input class="pl-2 mt-1 border-0" autofocus :placeholder="trans('search',280)" v-model="filters.search" :lazy="true"/>

                            <b-input-group-append is-text class="app-local-dropdown">
                                <b-dropdown variant="link" no-caret right>
                                    <template #button-content >
                                            <b-icon icon="three-dots-vertical" class="text-body"></b-icon>
                                    </template>
                                        <b-dropdown-item @click="filters.sort_desc = false"> {{trans('sort-az',283)}} </b-dropdown-item>
                                        <b-dropdown-item @click="filters.sort_desc = true"> {{trans('sort-za',283)}} </b-dropdown-item>
                                </b-dropdown>
                            </b-input-group-append>
                        </b-input-group>
                    </div>
                </div>
                <hr class="app-search-modal-separator">
                    <b-list-group flush class="app-local-list-group-custom-style">
                        <b-list-group-item class="d-flex  align-items-center app-local-list-group-item-custom-style" action v-for="item in items" :key="item.value" tabindex="0" @click.prevent="selectItem(item)">
                        <b-form-checkbox  v-model="item.selected" :value="1" unchecked-value="0" size="sm" :class="item.selected == 1 ? 'custom-checkbox-checked' : ''">
                        <span class="app-documents-item">{{item.name}}</span></b-form-checkbox>                    
                        </b-list-group-item>
                    </b-list-group>
            </b-card>
            <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',282)}}</app-button>
            <app-button type="primary"  @click="changeFunctie" :loading="loading_data">
                {{trans('save',282)}}
            </app-button>
        </template>
        </b-modal>
    </div> 
</template>

<script>
import axios from "@axios";

export default {

    props: ["show", "result", "args", "return"],

    created(){
        this.getList();
    },

    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },

        cReturn: {
            get() { return this.return },
            set() { this.$emit('update:return', false) }
        },

        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        }

    },

    watch: {
        filters: {
            handler: function(val) {
                if (this.filters.sort_by != "") {
                    this.loading = true;
                    this.getList();
                }
            },
            deep: true,
        },
    },

    data() {
        return {
            id_status_const_type:0,
            filters:{
                search:"",
                sort_by: "fct.name",
                sort_desc: false,
                args:[],
            },
            loading: false,
            loading_data: false,
            items:[],
        };
    },


    methods: {

        selectItem(item) {          
           var index = this.items.indexOf(item);
            if (item.selected === 1) {
                item.selected = 0;
            } else {
                item.selected = 1;  
            }             
            this.$set(this.items, index, item);
        },

       getList: function() {
            this.loading = true;
            axios
                .post("shifts/getFunctionsList", {
                    additional_names: this.args.additional_names,
                    id_cao_data_main: this.args.id_cao_data_main,
                    id_function_data_main: this.args.id_function_data_main,
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {
                    this.items = res.data;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading = 0;
                });
        },

        changeFunctie() {
            var additionalNames = "";
            var idsFunctionDataAdditional = "";

            for(var i = 0; i< this.items.length;i++){
                if(this.items[i].selected == 1){
                    if(additionalNames.length == 0){
                        additionalNames = this.items[i].name;
                        idsFunctionDataAdditional = this.items[i].id;
                    }else{
                        additionalNames = additionalNames + ", " + this.items[i].name;
                        idsFunctionDataAdditional = idsFunctionDataAdditional + ", " + this.items[i].id;
                    }
                }
            }
            
            this.cArgs.ids_function_data_additional = idsFunctionDataAdditional;
            this.cArgs.additional_names = additionalNames;

            this.$emit("update:show", false);
            this.$emit("update:result", 1);
        },

        closeModal() {
            this.$emit("update:show", false);
        },

    },
};
</script>

<style scoped>
.app-local-card-size{
    min-height:300px !important; 
    max-height:600px !important
}
.app-local-dropdown{
    width:65px
}
.app-local-icon{
    width:40px !important;
}
.app-local-list-group-custom-style{
    max-height:300px !important;
    overflow-y: auto;
}
.app-local-list-group-item-custom-style{
    cursor:pointer; 
    padding:4px 4px;
}
.input-group-text {
    border: none;
}
.ps{
    height: 350px;
}
.card-body {
    padding-top: 0.5rem;
}
</style>
