<template>
    <div>
        <b-modal ref="modal-add" size="sm" v-model="computedShow" no-close-on-backdrop :title="trans('kwalifications', 283)">
               <b-row v-for="(item, index) in cArgs.qualification_object" class="mb-2">
                    <b-col cols="12">
                        <app-select v-model="item.qualification" type="getQualificationsBulk" :args="cArgs.ids_qualification_const_type_querry" :key="refreshSelects" @change="changeAlias(item)"/>
                        <div v-overflow-tooltip class="app-local-font" v-bind:class="{'text-danger': item.valid == 0, 'text-muted': item.valid != 0}"> {{item.alias}} </div>
                    </b-col>
               </b-row>
            <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',282)}}</app-button>
            <app-button type="primary"  @click="change" :loading="loading">
                {{trans('save',282)}}
            </app-button>
        </template>
        </b-modal>

    </div> 
</template>

<script>
import axios from "@axios";
import AppAlerts from '@core/scripts/AppAlerts';

export default {
    components: {
    },

    props: ["show", "result", "args", "return"],

    created(){
    },

    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },

        cReturn: {
            get() { return this.return },
            set() { this.$emit('update:return', false) }
        },

        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        }

    },

    watch: {
        filters: {
            handler: function(val) {
                if (this.filters.sort_by != "") {
                    this.loading = true;
                    this.getList();
                }
            },
            deep: true,
        },

        "cArgs.qualification_object": {
            handler: function(val) {
                this.updateSelected();
            },
            deep: true,
        },
    },

    data() {
        return {
            loading: false,
            items:[],
            refreshSelects:0,
            alertClass: new AppAlerts(),
        };
    },


    methods: {
        updateSelected(){
            this.cArgs.ids_qualification_const_type_querry = [];
            this.cArgs.qualification_object.forEach(item => {  
                if(item.qualification.value){
                    this.cArgs.ids_qualification_const_type_querry.push(item.qualification.value)
                }
            });
            this.refreshSelects++
        },

        changeAlias(item){
            this.alertClass.openAlertConfirmation('Wil je een alias toevoegen?').then(res => {
                if(res == true){
                    this.loading = true;
                    axios
                        .post("shifts/addQualificationBulk", {
                            id_client_data_main: this.args.id_client_data_main,
                            id_qualification_const_type: item.qualification.value,
                            name_alias: item.alias
                        })
                        .then((res) => {
                            this.items = res.data;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }

                let non_matched_array = this.cArgs.non_matched_aliases.split(','); 
                non_matched_array = non_matched_array.filter(e => e.trim().toLowerCase() !== item.alias.trim().toLowerCase());
                if(non_matched_array.length > 0){
                    this.cArgs.non_matched_aliases = non_matched_array.join(",");
                }else{
                    this.cArgs.non_matched_aliases = '';
                }
                
                item.valid = 1;
            })
        },

        change() {
            var qualificationNames = "";
            var idsQualificationConstType = [];

            this.cArgs.qualification_object.forEach(item => {  
                if(item.qualification.value){
                    if(qualificationNames.length == 0){
                        qualificationNames = item.qualification.name;
                        idsQualificationConstType.push(item.qualification.value);
                    }else{
                        qualificationNames = qualificationNames + ", " + item.qualification.name;
                        idsQualificationConstType.push(item.qualification.value);
                    }
                }
            });
            
            this.cArgs.ids_qualification_const_type = idsQualificationConstType;
            this.cArgs.qualification_names = qualificationNames;

            this.$emit("update:show", false);
            this.$emit("update:result", 1);
        },

        closeModal() {
            this.$emit("update:show", false);
        },

    },
};
</script>

<style scoped>
.app-local-font{
    font-size: 0.75rem;
    text-align: right;
    margin-right: 10px
}

.app-local-card-size{
    min-height:300px !important; 
    max-height:600px !important
}
.app-local-dropdown{
    width:65px
}
.app-local-icon{
    width:40px !important;
}
.app-local-list-group-custom-style{
    max-height:300px !important;
    overflow-y: auto;
}
.app-local-list-group-item-custom-style{
    cursor:pointer; 
    padding:4px 4px;
}
.input-group-text {
    border: none;
}
.ps{
    height: 350px;
}
.card-body {
    padding-top: 0.5rem;
}

</style>
