<template>
    <div>
        <b-input-group class="input-group-merge" >

            <b-form-input class="bg-white custom-search" v-model="cPersonName" size="sm" disabled @click="searchPerson()" data-toggle="tooltip" :title="cPersonName"/>

            <b-input-group-append is-text >
                <button  v-if="cPersonName != null && cPersonName.length > 0" type="button" :title="trans('clear-selected',280)" :aria-label="trans('clear-selected',280)" class="vs__clear" style="" @click="clear()"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10"><path d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"></path></svg></button>
                <b-icon icon="search" class="custom-search font-weight-800" font-scale="1.1" @click="searchPerson()"></b-icon> 
            </b-input-group-append>
        </b-input-group>

        <offers-shifts-add-bulk-search-person-component v-if="mPersonSearch.show" :show.sync="mPersonSearch.show" :result.sync="mPersonSearch.result" :args.sync="mPersonSearch.args"/>
    </div>
</template>
<script>


import axios from "@axios";
import OffersShiftsAddBulkSearchPersonComponent from './OffersShiftsAddBulkSearchPersonComponent.vue'

export default {

    components: {
        OffersShiftsAddBulkSearchPersonComponent
    },

    props: {
            filePersonId: { type: String },
            personId: { type: Number },
            personName: { type: String },
            function: {type: Object},
            idOfficeLocation: {type: Number},
            department: {type: Object},
            isSwitch: { type: Number },
            applicantType: {},
            zzpPreferences: {}
    },

    computed: {
        
        cPersonName: {
            get() { return this.personName },
            set(value) { this.$emit('update:personName', value) }
        },
        cPersonId: {
            get() { return this.personId },
            set(value) { this.$emit('update:personId', value) }
        },
        cIsSwitch: {
            get() { return this.isSwitch },
            set(value) { this.$emit('update:isSwitch', value) }
        },
        cApplicantType: {
            get() { return this.applicantType },
            set(value) { this.$emit('update:applicantType', value) }
        },
    },

    watch: {
        "mPersonSearch.result": {
            handler: function(val) {
                if (val == 1) {
                    this.cPersonId = this.mPersonSearch.args.id_person_data_main;
                    this.cPersonName = this.mPersonSearch.args.person_name;
                    this.cApplicantType = this.mPersonSearch.args.applicant_type;
                    this.cIsSwitch = this.mPersonSearch.args.is_switch;
                    this.mPersonSearch.result = 0;
                    this.$emit('setPerson', null);
                }                
            },
            deep:true
        },
    },

    data() {
        return {
            mPersonSearch: { show: false, args: {
                avatar: null,
                id_office_data_person: null,
                id_function_data_person: null,
                function_name: null,
                id_person_data_main:null,
                person_name:null,
                person_type: null,
                sex:null,
                id_department_data_main: null,
                id_office_location: null,
                id_function_data_main: null,
                ids_function_data_additional: null,
                ids_function: null,
                id_cao_data_main: null,
                is_switch: null,
                applicant_type: null,
                zzp_preferences: null
            }, result: 0, return: null },
        };
    },

    methods: {
        searchPerson(){
            if(this.function && this.department){
                this.mPersonSearch.args.id_person_data_main = this.personId;
                this.mPersonSearch.args.is_switch = this.isSwitch;
                this.mPersonSearch.args.applicant_type = this.applicantType;
                this.mPersonSearch.args.id_department_data_main = this.department.id_department_data_main;
                this.mPersonSearch.args.person_name = this.personName;
                this.mPersonSearch.args.id_office_location = this.idOfficeLocation;
                this.mPersonSearch.args.id_function_data_main = this.function ? this.function.id_function_data_main : null;
                this.mPersonSearch.args.id_cao_data_main = this.department ? this.department.id_cao_data_main : null;
                // this.mPersonSearch.args.ids_function_data_additional =  this.function && this.function.ids_function_data_additional ? this.function.ids_function_data_additional.toString().split(', ').map(Number) : [];
                if(this.function  && this.function.ids_function_data_additional){
                    this.mPersonSearch.args.ids_function_data_additional = typeof this.function.ids_function_data_additional == 'string' ? this.function.ids_function_data_additional.split(', ').map(Number) : [this.function.ids_function_data_additional]
                } else {
                    this.mPersonSearch.args.ids_function_data_additional = [];
                }
                this.mPersonSearch.args.zzp_preferences = this.zzpPreferences;
                this.mPersonSearch.show = true;
            }
        },

        clear(){
            this.cPersonName = null;
            this.cPersonId = null;
            this.cIsSwitch = null;
            this.cApplicantType = null;
            this.$emit('setPerson', null);
        }
        
    }

    };
</script>

<style scoped>
.input-group-text {
    padding: 0px 10px !important
}
.custom-search{
    cursor: pointer;
    text-overflow: ellipsis;
}

.custom-search:hover{
    color: rgba(0,0,0,.8) !important;
}

</style>