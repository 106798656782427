<template>
    <div>
        <b-card class="mt-2" >
        <app-view-table title="" :topFiltersCount="1" :isSelectable="true" :isRowHover="false" :loading="loadingList" :filters.sync="cPlanData.shifts.filters" :items.sync="items" :fields="fields" :customColumns="customColumns" @onRowClicked="onRowClicked" :customColumnsHeaders="custom_columns_headers" :resizeableColumns="true" :resizeMinWidth="true">
            
            <template v-slot:view-title>
                <div v-overflow-tooltip> {{trans('sh-create-bulk-service',314)}} <span v-if="!loadingList && cPlanData.shifts != null && cPlanData.shifts.items != null">({{ cPlanData.shifts.items.total }})</span></div>
            </template>

            <template v-slot:head_description="cell">
                <div class="app-table-resizeable-header">
                    <span data-toggle="tooltip" :title="cell.data.label">{{trans('td-description-short',314)}}</span>
                </div>
            </template>

            <template v-slot:filter-col-1>
                <b-button-group  size="sm"  >
                    <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': type !== 1, 'app-list-button-selected':  type === 1 }" @click="setListType(1)">{{trans('sh-ready-to-import',314)}} ( {{items.total_done}} )</b-btn>
                    <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': type !== 2, 'app-list-button-selected':  type === 2 }" @click="setListType(2)">{{trans('sh-action-required',314)}} ( {{items.total_undone}} )</b-btn>
                </b-button-group> 
            </template>


            <template v-slot:department="cell">
                <div v-if="cell.data.rowSelected">
                    <div @click.stop>
                        <app-select :appendToBody="true" :ref="'vrDepartment' + cell.data.item.id_temp" v-model="cell.data.item.department" :disable="cPlanData.import.level == 2" :options="departments" @change="setDepartment($event, cell.data.item)" :key="refresh" /> 
                      </div>
                    <small v-overflow-tooltip class="ml-1" v-bind:class="{ 'text-danger': cell.data.item.department == null, 'text-muted' : cell.data.item.department != null}"> {{cell.data.item.department_source}} </small> 
                </div>                
                <div v-else v-overflow-tooltip class="ml-2" v-bind:class="{ 'text-danger': cell.data.item.department == null}"> {{cell.data.item.department == null ? cell.data.item.department_source : cell.data.item.department.name}} </div>                     
            </template>  

            <template v-slot:function="cell">
               <div v-if="cell.data.rowSelected">
                    <div @click.stop>
                      <app-select :appendToBody="true" :ref="'vrFunctions' + cell.data.item.id_temp" v-model="cell.data.item.function" :selectOnTab="true" :options="reduceFunctions(cell.data.item.department)" @change="setFunction($event, cell.data.item)" :key="refresh" :disable="cell.data.item.department == null"/>
                    </div>
                    <small v-overflow-tooltip class="ml-1" v-bind:class="{ 'text-danger': cell.data.item.function == null, 'text-muted' : cell.data.item.function != null}"> {{cell.data.item.function_name_source}} </small> 
                </div>                
                <div v-else v-overflow-tooltip class="ml-2" v-bind:class="{ 'text-danger': cell.data.item.function == null}"> {{cell.data.item.function == null ? cell.data.item.function_name_source : cell.data.item.function.name}} </div>                 
            </template>      
            
            <template v-slot:qualifications="cell">
                 <div v-if="cell.data.rowSelected">
                    <div @click.stop>               
                        <offers-shifts-add-bulk-qualifications v-if="cell.data.rowSelected" :qualifications.sync="cell.data.item.qualifications" :disabled="cell.data.item.qualifications == null" :checkQualification.sync="checkQualification" :item.sync="cell.data.item" :idClientDataMain="cPlanData.client ? cPlanData.client.id_client_data_main : cPlanData.client_location.id_client_data_main"/>
                    </div>
                    <small v-overflow-tooltip class="ml-1" v-bind:class="{ 'text-danger': cell.data.item.qualifications.non_matched_aliases.length > 0, 'text-muted' : cell.data.item.qualifications.non_matched_aliases.length == 0 && cell.data.item.qualifications.qualification_error == 0}">{{cell.data.item.qualifications.non_matched_aliases.length > 0 ? cell.data.item.qualifications.non_matched_aliases : cell.data.item.qualifications.qualifications_name_source}} </small> 
                </div>    
                <div v-else v-overflow-tooltip class="ml-2" v-bind:class="{ 'text-danger': cell.data.item.qualifications.qualification_error == 1}"> {{cell.data.item.qualifications.ids_qualification_const_type.length > 0 ? cell.data.item.qualifications.qualification_names : cell.data.item.qualifications.qualification_error == 1 ? cell.data.item.qualifications.non_matched_aliases : ''}} </div>      
                <div v-if="!cell.data.rowSelected && cell.data.item.qualifications.non_matched_aliases.length > 0 && cell.data.item.qualifications.qualification_error == 0" v-overflow-tooltip class="ml-2 text-danger"> {{cell.data.item.qualifications.non_matched_aliases}} </div>                            
            </template>

            <template v-slot:date_from="cell">
                <div v-if="cell.data.rowSelected">
                    <div @click.stop>               
                        <app-date :ref="'vrDate' + cell.data.item.id_temp" v-model="cell.data.item.date_from" @input="setDate($event, cell.data.item)" :validatorCustomMessage="{ 'disable-date-past': trans('incorrect-date',281), 'disable-date-future': trans('date-from-more-than-year-error',281) }" :disabledDatesPass="1" :disabledDatesFuture="373"/>
                    </div>
                    <small v-overflow-tooltip class="ml-1" v-bind:class="{ 'text-danger': cell.data.item.date_from == undefined || isDateValid(cell.data.item.date_from, cell.data.item.hour_from), 'text-muted' : cell.data.item.date_from != undefined && !isDateValid(cell.data.item.date_from, cell.data.item.hour_from)}"> {{cell.data.item.date_from_source}} </small> 
                </div>
                <div v-else v-overflow-tooltip class="ml-2" :class="{'text-danger': isDateValid(cell.data.item.date_from, cell.data.item.hour_from)}"> {{cell.data.item.date_from}} </div>
            </template>
         
            <template v-slot:hour_from="cell">
               <div v-if="cell.data.rowSelected">
                    <div @click.stop>               
                        <app-time v-model="cell.data.item.hour_from" @input="calculateShiftType(cell.data.item)"/>
                    </div>
                    <small v-if="isTimeValid(cell.data.item.date_from, cell.data.item.hour_from)" class="ml-1 text-error app-input-error-ellips" data-toggle="tooltip" :title="trans('start-time-must-be-in-future',281)">{{trans('start-time-must-be-in-future',281)}}</small>        
                    <small v-overflow-tooltip class="ml-1" v-bind:class="{ 'text-danger': cell.data.item.hour_from == undefined || isTimeValid(cell.data.item.date_from, cell.data.item.hour_from), 'text-muted' : cell.data.item.hour_from != undefined && !isTimeValid(cell.data.item.date_from, cell.data.item.hour_from)}"> {{cell.data.item.hour_from_source}} </small> 
                </div>    
                <div v-else v-overflow-tooltip class="ml-2" :class="{'text-danger': isTimeValid(cell.data.item.date_from, cell.data.item.hour_from)}"> {{cell.data.item.hour_from}} </div>
            </template>

            <template v-slot:hour_to="cell">               
                <div v-if="cell.data.rowSelected">
                    <div @click.stop>               
                        <app-time v-model="cell.data.item.hour_to" @input="calculateShiftType(cell.data.item)"/>
                    </div>                    
                    <small v-overflow-tooltip class="ml-1" v-bind:class="{ 'text-danger': cell.data.item.hour_to == undefined, 'text-muted' : cell.data.item.hour_to != undefined}"> {{cell.data.item.hour_to_source}} </small>
                </div>    
                <div v-else v-overflow-tooltip class="ml-2"> {{cell.data.item.hour_to}} </div>
            </template>

            <template v-slot:shift_type="cell">
               <div v-if="cell.data.rowSelected">
                    <div @click.stop>               
                        <app-select :appendToBody="true" :clearable="false" v-model="cell.data.item.shift_type" :options="cell.data.item.department && cell.data.item.department.is_sleep_shift && cell.data.item.department.is_sleep_shift == 1 ? shift_type_class.types : shift_types_without_sleep" :disable="cell.data.item.hour_from == null || cell.data.item.hour_to == null" @change="setDaypart(cell.data.item)"/>
                    </div>                    
                    <small v-overflow-tooltip class="ml-1 text-danger" v-if="!cell.data.item.shift_type && cell.data.item.shift_type_name_source == null || cell.data.item.shift_type_name_source == ''"> {{trans('field-required-error',280)}} </small>
                    <small v-overflow-tooltip class="ml-1" v-bind:class="{ 'text-danger': !cell.data.item.shift_type || !cell.data.item.shift_type.name}" v-else="!cell.data.item.shift_type && cell.data.item.shift_type_name_source == null || cell.data.item.shift_type_name_source == ''"> {{cell.data.item.shift_type_name_source ? cell.data.item.shift_type_name_source : ''}} </small> 
 
                </div>    
                <div v-else-if="!cell.data.item.shift_type && cell.data.item.shift_type_name_source == null || cell.data.item.shift_type_name_source == ''" v-overflow-tooltip class="ml-2 text-danger">{{ trans('field-required-error',280) }}</div>
                <div v-else v-overflow-tooltip class="ml-2" v-bind:class="{ 'text-danger': !cell.data.item.shift_type || !cell.data.item.shift_type.name}"> {{cell.data.item.shift_type && cell.data.item.shift_type.name ? cell.data.item.shift_type.name : cell.data.item.shift_type_name_source}} </div>
            </template>

            <template v-slot:addictional_functions="cell">
                 <div v-if="cell.data.rowSelected">
                    <div @click.stop>               
                        <offers-shifts-add-bulk-additional-functions v-if="cell.data.rowSelected" :function.sync="cell.data.item.function" :department="cell.data.item.department" :disabled="cell.data.item.department == null || cell.data.item.function == null"/>
                    </div>
                    <small v-overflow-tooltip class="ml-1" v-bind:class="{ 'text-danger': cell.data.item.functions_name_source == undefined, 'text-muted' : cell.data.item.functions_name_source != undefined}">  {{cell.data.item.functions_name_source}} </small> 
                </div>    
                <div v-else v-overflow-tooltip class="ml-2"> {{cell.data.item.function != null && cell.data.item.function.additional_names != null  ? cell.data.item.function.additional_names : cell.data.item.functions_source}} </div>
            </template>
            

            <template v-slot:person_name="cell">
                <div v-if="cell.data.rowSelected">
                    <div @click.stop>            
                        <offers-shifts-add-bulk-person-input  v-if="cell.data.rowSelected" :personName.sync="cell.data.item.person_name" :personId.sync="cell.data.item.id_person_data_main" :department="cell.data.item.department" :function="cell.data.item.function" :isSwitch.sync="cell.data.item.is_switch" :applicantType.sync="cell.data.item.applicant_type" :idOfficeLocation="planData.office_location.value" :zzpPreferences="cell.data.item.department ? cell.data.item.department.zzp_preferences : null" @setPerson="setPerson(cell.data.item.id_temp)"/>
                    </div>
                    <small v-if="cell.data.item.id_person_data_main == null && isInPast(cell.data.item.date_from)" v-overflow-tooltip class="ml-2 text-danger">{{ trans('field-required-error',280) }}</small>
                    <small v-else-if="cell.data.item.person_name == null" v-overflow-tooltip class="ml-2" v-bind:class="{ 'text-danger': cell.data.item.person_name == null}"> {{cell.data.item.id_person_data_main_source == null ? '' : cell.data.item.id_person_data_main_source}} </small>
                    <small v-else v-overflow-tooltip class="ml-1" v-bind:class="{ 'text-danger': cell.data.item.id_person_data_main_source == undefined, 'text-muted' : cell.data.item.person_name_source != undefined}">  {{cell.data.item.person_name_source}} </small>
                </div>
                <div v-else-if="cell.data.item.id_person_data_main == null && isInPast(cell.data.item.date_from)" v-overflow-tooltip class="ml-2 text-danger">{{ trans('field-required-error',280) }}</div>
                <div v-else-if="cell.data.item.person_name != null" v-overflow-tooltip class="ml-2" v-bind:class="{ 'text-danger': cell.data.item.person_name == null}"> {{cell.data.item.person_name == null ? cell.data.item.person_name_source : cell.data.item.person_name}} </div>
                <div v-else v-overflow-tooltip class="ml-2" v-bind:class="{ 'text-danger': cell.data.item.person_name == null}"> {{cell.data.item.id_person_data_main_source == null ? '' : cell.data.item.id_person_data_main_source}} </div>
                
           </template>

           <template v-slot:applicant_type="cell">
                <div v-if="cell.data.rowSelected">
                    <div @click.stop>               
                        <app-select :appendToBody="true" :clearable="false" v-model="cell.data.item.applicant_type" :options="applicant_types" @input="setItems()" :disable="cell.data.item.is_switch != 1" :key="refresh"/>
                    </div>                    
                </div>    
                <div v-else v-overflow-tooltip class="ml-2" > {{cell.data.item.applicant_type && cell.data.item.applicant_type.name ? cell.data.item.applicant_type.name : ''}} </div>     
            </template>

            <template v-slot:description="cell">    
                <offers-shifts-add-bulk-description-change :description.sync="cell.data.item.description" :id="cell.data.item.id_temp"/>
            </template>

            <template v-slot:pause="cell">
               <div v-if="cell.data.rowSelected">
                    <div @click.stop>               
                        <app-select :appendToBody="true" :clearable="true" v-model="cell.data.item.pause" :options="pause_types" @input="setItems()"/>
                    </div>                    
                </div>    
                <div v-else v-overflow-tooltip class="ml-2" > {{cell.data.item.pause && cell.data.item.pause.name ? cell.data.item.pause.name : ''}} </div>     
            </template>

            <template v-slot:shift_code="cell">
               <div v-if="cell.data.rowSelected">
                    <div @click.stop>               
                        <app-input v-model="cell.data.item.shift_code" :validatorName="trans('sh-shiftcode',314)" mask="lettersE6-50" @input="setItems()"/>
                    </div>                    
                </div>    
                <div v-else v-overflow-tooltip class="ml-2" > {{cell.data.item.shift_code ? cell.data.item.shift_code : ''}} </div>     
            </template>

            <template v-slot:employment="cell">
                <div v-if="cell.data.rowSelected">
                    <div @click.stop class="d-flex align-items-center">                                
                        <app-select :appendToBody="true" :clearable="false" v-model="cell.data.item.employment" :options="employments_list" @input="setItems()"/>
                    </div>
                </div>    
                <div v-else v-overflow-tooltip class="ml-2 d-flex align-items-center" >
                    <span>{{cell.data.item.employment && cell.data.item.employment.name ? cell.data.item.employment.name : ''}}</span>
                </div>     
            </template>

            <template v-slot:employment_info="cell">
                <div v-if="cell.data.rowSelected">
                    <div @click.stop>                                
                        <b-icon v-if="cell.data.item.department && cell.data.item.department.zzp_preferences != cell.data.item.employment.value" icon="info-circle-fill" :id="'popover-employment-change-' + cell.data.item.id_temp" :key="'popover-employment-change-' + cell.data.item.id_temp" class="ml-2 app-cursor-pointer app-local-icon-employment"/>

                        <b-popover :target="'popover-employment-change-' + cell.data.item.id_temp" triggers="hover" no-fade>               
                            <template #title>
                                
                            </template>
                            <span class="text-small">{{trans('sh-default-employment-relationship', 292, {name: cell.data.item.department && cell.data.item.department.zzp_preferences != cell.data.item.employment.value ? employments_list.filter(item => item.value == cell.data.item.department.zzp_preferences)[0].name : ''})}}</span>
                        </b-popover>
                    </div>
                </div>    
                <div v-else v-overflow-tooltip>
                    <b-icon v-if="cell.data.item.department && cell.data.item.department.zzp_preferences != cell.data.item.employment.value" icon="info-circle-fill" :id="'popover-employment-change-' + cell.data.item.id_temp" :key="'popover-employment-change-' + cell.data.item.id_temp" class="ml-2 app-cursor-pointer app-local-icon-employment"/>

                    <b-popover :target="'popover-employment-change-' + cell.data.item.id_temp" triggers="hover" no-fade>               
                        <template #title>
                            
                        </template>
                        <span class="text-small">{{trans('sh-default-employment-relationship', 292, {name: cell.data.item.department && cell.data.item.department.zzp_preferences != cell.data.item.employment.value ? employments_list.filter(item => item.value == cell.data.item.department.zzp_preferences)[0].name : ''})}}</span>
                    </b-popover>
                </div>     
            </template>
            
            <template v-slot:menu-items="cell">
                
                <div v-if="cell != null && cell.data != null" >
                    <b-dropdown-item :disabled="cell.data.item.function == null" @click="changeFunctionAlias(cell.data.item.function.id_function_data_main)" >{{trans('sh-function-aliases',280)}}</b-dropdown-item>
                    <b-dropdown-item :disabled="cell.data.item.department == null" @click="changeDepartmentAlias(cell.data.item.department.id_department_data_main)" >{{trans('sh-department-aliases',314)}}</b-dropdown-item>
                    <b-dropdown-item :disabled="cell.data.item.qualifications.ids_qualification_const_type.length == 0" @click="changeQualificationAliasStep1(cell.data.item.qualifications.ids_qualification_const_type)">{{trans('sh-qualification-aliases', 280)}}</b-dropdown-item>
                    <b-dropdown-item :disabled="cell.data.item.shift_type == null || !cell.data.item.shift_type.value" @click="changeDaytimeAlias(cell.data.item.shift_type)" >{{trans('cp-service-type-alias', 314)}}</b-dropdown-item>
                </div>
            </template>


        </app-view-table>
        <div v-if="planData.import_error == true" class="mt-2"> 
            <small class="text-danger" v-if="planData.import.level < 2">{{trans('sh-all-must-be-filled-in',314)}}</small>  
            <small class="text-danger" v-if="planData.import.level == 2">{{trans('sh-all-dates-times-functions-must-be-filled',314)}}</small>  
        </div> 
        <div v-if="planData.in_past_error == true" class="mt-2">
            <small class="text-danger">{{trans('sh-in-past-fw-is-required',314)}}</small>
        </div>
        </b-card>
        <client-profile-department-change-alias  v-if="mDepartmentAliasChange.show" :show.sync="mDepartmentAliasChange.show" :result.sync="mDepartmentAliasChange.result" :args="mDepartmentAliasChange.args" />
        <settings-type-change-alias v-if="mFunctionAliasChange.show" :show.sync="mFunctionAliasChange.show" :result.sync="mFunctionAliasChange.result" :args="mFunctionAliasChange.args" />
        <offers-shifts-add-bulk-alert v-if="mAlert.show" :show.sync="mAlert.show" :result.sync="mAlert.result" :args="mAlert.args" />
        <offers-shifts-add-bulk-qualifications-change-alias-step1 v-if="mChangeQualificationsAliasesStep1.show" :show.sync="mChangeQualificationsAliasesStep1.show" :result.sync="mChangeQualificationsAliasesStep1.result" :args="mChangeQualificationsAliasesStep1.args" :return.sync="mChangeQualificationsAliasesStep1.return"/>
        <offers-shifts-add-bulk-qualifications-change-alias-step2 v-if="mChangeQualificationsAliasesStep2.show" :show.sync="mChangeQualificationsAliasesStep2.show" :result.sync="mChangeQualificationsAliasesStep2.result" :args="mChangeQualificationsAliasesStep2.args" />
        <offers-shifts-add-bulk-daytime-change-alias v-if="mChangeDaytimeAliasesStep2.show" :show.sync="mChangeDaytimeAliasesStep2.show" :args.sync="mChangeDaytimeAliasesStep2.args" :result.sync="mChangeDaytimeAliasesStep2.result"/>

    </div>
</template>
<script>


import axios from "@axios";
import moment from "moment";
import ShiftsTypeClass from "@core/scripts/ShiftsTypeClass.js"
import { datecheck } from "@validations";
import OffersShiftsAddBulkPersonInput from './OffersShiftsAddBulkPersonInput.vue';
import OffersShiftsAddBulkDescriptionChange from './OffersShiftsAddBulkDescriptionChange.vue';
import OffersShiftsAddBulkAdditionalFunctions from './OffersShiftsAddBulkAdditionalFunctions.vue';
import AppAlerts from '@core/scripts/AppAlerts';
import ClientProfileDepartmentChangeAlias from './ClientProfileDepartmentChangeAlias.vue';
import SettingsTypeChangeAlias from './SettingsTypeChangeAlias.vue'
import OffersShiftsAddBulkAlert from './OffersShiftsAddBulkAlert.vue';
import OffersShiftsAddBulkQualifications from './OffersShiftsAddBulkQualifications.vue';
import OffersShiftsAddBulkQualificationsChangeAliasStep1 from './OffersShiftsAddBulkQualificationsChangeAliasStep1.vue';
import OffersShiftsAddBulkQualificationsChangeAliasStep2 from './OffersShiftsAddBulkQualificationsChangeAliasStep2.vue';
import OffersShiftsAddBulkDaytimeChangeAlias from './OffersShiftsAddBulkDaytimeChangeAlias.vue';


export default {
    components: {
        OffersShiftsAddBulkPersonInput,
        OffersShiftsAddBulkAdditionalFunctions,
        ClientProfileDepartmentChangeAlias,
        SettingsTypeChangeAlias,
        OffersShiftsAddBulkDescriptionChange,
        OffersShiftsAddBulkAlert,
        OffersShiftsAddBulkQualifications,
        OffersShiftsAddBulkQualificationsChangeAliasStep1,
        OffersShiftsAddBulkQualificationsChangeAliasStep2,
        OffersShiftsAddBulkDaytimeChangeAlias
    },

    props: {
        planData: {},
    },

    watch: {
        "mAlert.result": function(val) {
            if (val === 1) {

                this.mAlert.result = 0;
                if(this.mAlert.args.option == 1){
                    this.mAlert.args.sameDepartmentItems.forEach(item => {
                        item.department = this.mAlert.args.selectedOption;
                        this.checkFunction(item); 
                        this.validateItem(item.id_temp);
                    });  
                }else if(this.mAlert.args.option == 3){
                    this.mAlert.args.selectedItem.department = null;
                    this.mAlert.args.selectedItem.function = null;
                    this.mAlert.args.selectedItem.list_type = 2;
                }

                if(this.mAlert.args.option < 3){
                    this.setDepartmentAlias(this.mAlert.args.selectedItem);
                    this.checkFunction(this.mAlert.args.selectedItem); 
                    this.validateItem(this.mAlert.args.selectedItem.id_temp);
                }

            }else if(val === 2){

                this.mAlert.result = 0;
                if(this.mAlert.args.option == 1){
                    this.mAlert.args.sameFunctionItems.forEach(item => {
                        item.function = this.mAlert.args.selectedOption;
                        this.validateItem(item.id_temp);
                    }); 
                }else if(this.mAlert.args.option == 3){
                    this.mAlert.args.selectedItem.function = null;
                    this.mAlert.args.selectedItem.list_type = 2;
                }

                if(this.mAlert.args.option < 3){
                    this.setFunctionsAlias(this.mAlert.args.selectedItem);
                    this.validateItem(this.mAlert.args.selectedItem.id_temp);
                }
            
            }
            this.setItems();
        },

        checkQualification: {
            handler: function(val){
                this.validateItem(this.checkQualification);
                this.setItems();
            },

            deep:true
        },

        "items.list": {
            handler: function(val){
                if (Array.isArray(val)) {
                    val.forEach(item => {
                        if (item.department && item.department.zzp_preferences != null) {
                            if (![1, 2, 3].includes(item.employment.value)) {
                                item.employment.value = item.department.zzp_preferences;
                                item.employment.name = this.employments_list.find(e => e.value === item.department.zzp_preferences).name;
                            }
                        }
                    });
                }
            },
            deep:true
        }
    },

    computed: {
        cPlanData:{
            get() { return this.planData },
            set() { this.$emit('update:planData', false) }
        },
        cCanFunctionSort: {
            get(){
                var result = true;

                this.items.list.forEach(item => {
                    if(item.function == null){
                        result = false;
                    }
                });


                return (result && this.type == 2) || this.type == 1;
            }
        }
    },

    mounted() {
        this.onCreateThis();
    },

    data() {
        return {
            items: { total: 0, total_done: 0, total_undone:0,  list: [], refresh:0},
            first_items: null,
            checkQualification: 0,
            refresh:0, 
            type: 1,
            customColumns: ["department", "function", "date_from", "hour_from", "hour_to", "shift_type", "addictional_functions", "person_name", "description", "pause", "shift_code", "qualifications", "employment", "employment_info", "applicant_type"],
            custom_columns_headers: ["description"],
            departments:[],
            functions: [],
            loadingList: false,
            applicant_types: [
                { value: 1, name: 'UZK' },
                { value: 2, name: 'ZZP' }
            ],
            pause_types: [
                { value: 1, name: this.trans('no-option',280) },
                { value: 2, name: this.trans('yes-option',280) },
            ],
            shift_types_without_sleep: [
                { value: 1, name: "Ochtend" },
                { value: 2, name: "Middag" },
                { value: 3, name: "Dag"},
                { value: 4, name: "Avond" },
                { value: 5, name: "Nacht" }
            ],
            fields: [
                { key: "department", label: this.trans('department',280), thStyle: { "min-width": "80px", width: "100%" }, tdClass: "app-local-top-align", visible: true, filtering: false},
                { key: "function", label: this.trans('sh-function',292), thStyle: { "min-width": "150px", "max-width": "450px" }, tdClass: "app-local-top-align", visible: true, filtering: false},
                { key: "date_from", label: this.trans('pl-date',288), thStyle: { "min-width": "140px", "max-width": "350px" }, tdClass: "app-local-top-align", visible: true, filtering: false},
                { key: "hour_from", label: this.trans('sh-begin',292), thStyle: { "min-width": "80px", "max-width": "310px" }, tdClass: "app-local-top-align", visible: true, filtering: false, sortable: false},
                { key: "hour_to", label: this.trans('sh-end',292), thStyle: { "min-width": "80px", "max-width": "310px" }, tdClass: "app-local-top-align", visible: true, filtering: false, sortable: false},
                { key: "shift_type", label: this.trans('sh-type-of-service',292), thStyle: { "min-width": "120px", "max-width": "330px" }, tdClass: "app-local-top-align", visible: true, filtering: false, sortable: false},
                { key: "addictional_functions", label: this.trans('deviant-funtions',314), thStyle: { "min-width": "100px", "max-width": "400px" }, tdClass: "app-local-top-align", visible: true, filtering: false, sortable: false},
                { key: "person_name", label: this.trans('flexworker',280), thStyle: { "min-width": "100px", "max-width": "310px"}, tdClass: "app-local-top-align", visible: true, filtering: false, sortable: false},
                { key: "applicant_type", label: this.trans('pl-applicant-type', 314), thStyle: { "min-width": "100px", "max-width": "310px"}, tdClass: "app-local-top-align", visible: true, filtering: false, sortable: false},
                { key: "description", label: this.trans('sh-description-label',314), thStyle: { "min-width": "50px", "max-width": "150px"}, tdClass: "app-local-top-align app-local-row-center", visible: true, filtering: false, sortable: false},
                { key: "pause", label: this.trans('h-pause',305), thStyle: { "min-width": "115px", "max-width": "330px" }, tdClass: "app-local-top-align", visible: true, filtering: false, sortable: false},
                { key: "qualifications", label: this.trans('kwalifications', 283), thStyle: { "min-width": "100px", "max-width": "400px" }, tdClass: "app-local-top-align", visible: true, filtering: false, sortable: false},
                { key: "shift_code", label: this.trans('sh-shift-codee',292), thStyle: { "min-width": "115px", "max-width": "330px" }, tdClass: "app-local-top-align", visible: true, filtering: false, sortable: false},
                { key: "employment", label: this.trans('planning-employment',288), thStyle: { "min-width": "200px", "max-width": "330px" }, tdClass: "app-local-top-align", visible: true, filtering: false, sortable: false},
                { key: "employment_info", label: '', thStyle: { "min-width": "50px", "max-width": "50px" }, tdClass: "app-local-top-align", visible: true, filtering: false, sortable: false},
            ],
            alertClass: new AppAlerts(),
            shift_type_class :new ShiftsTypeClass(),
            mDepartmentAliasChange: { show: false, args: { 
                                                            id_department_data_main: null,
                                                            alias: null
                                                        },
                                            result: 0, return: null },
            mFunctionAliasChange: { show: false, args: { 
                                                            id_function_data_main: null,
                                                            id_client_data_main: null,
                                                            alias: null
                                                        },
                                            result: 0, return: null },
            mChangeQualificationsAliasesStep1: {show: false, args: {
                                                                id_client_data_main: null,
                                                                ids_qualification_const_type: null,
                                                            },
                                            result: 0, return: null},
            mChangeQualificationsAliasesStep2: {show: false, args: {
                                                                id_qualification_const_type: null, 
                                                                id_client_data_main: null,
                                                                name_aliases: null,
                                                                filters: null,
                                                            },
                                            result: 0, return: null},
            is_row_clicked: false,
            mAlert: { show: false, args: { 
                                                            sameDepartmentItems: null,
                                                            sameFunctionItems: null,
                                                            option: null,
                                                            selectedItem: null,
                                                            selectedOption: null,
                                                            type: null,
                                                        },
                                            result: 0, return: null },
            mChangeDaytimeAliasesStep2: {
                show: false, args: { 
                    id_client_data_main: null,
                    id_shift_const_daytime: null,
                    new_alias: null
                }, result: 0, return: null,
            },

            employments_list: [
                { value: 2, name: this.trans('sh-uzk',292) },
                { value: 3, name: this.trans('sh-zzp',292) },
                { value: 1, name: this.trans('sh-uzk-zzp',292) }
            ],

        };
    },


    methods: {

        setListType(argType) {
            this.type = argType;
            this.cPlanData.shifts.filters.page = 1;
            this.setItems();
        },

        getList() {
           this.loadingList = true;
           this.cPlanData.shifts.items = [];
           this.items = [];
           
           axios
                .post("shifts/checkImportData", {
                    plan_data: JSON.stringify(this.cPlanData),
                })
                .then((res) => {    
                   
                    this.departments = res.data.departments;
                    this.functions = res.data.functions;
                    this.cPlanData.shifts.items = res.data.items;

                    if(this.cPlanData.shifts.items.list.filter(element => element.list_type == 1).length == 0){
                        this.setListType(2)
                    }else{
                        this.setListType(1)
                    }
                    this.setItems(1);
                    this.fields[1].sortable = this.cCanFunctionSort;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                  //  this.cTotal = this.items.total;
                    this.loadingList = false;
                });
        },

        changeDepartmentAlias(id_department_data_main, alias = null){
            this.mDepartmentAliasChange.args.id_department_data_main = id_department_data_main;
            this.mDepartmentAliasChange.args.alias = alias;
            this.mDepartmentAliasChange.show = true;
        },

        changeFunctionAlias(id_function_data_main, alias = null){
            if(this.cPlanData.client){
                this.mFunctionAliasChange.args.id_client_data_main = this.cPlanData.client.id_client_data_main;
            }else{
                this.mFunctionAliasChange.args.id_client_data_main = this.cPlanData.client_location.id_client_data_main;
            }
            this. mFunctionAliasChange.args.id_function_data_main = id_function_data_main;
            this.mFunctionAliasChange.args.alias = alias;
            this.mFunctionAliasChange.show = true;
        },

        setItems (type) {
                if (this.cPlanData.shifts.items.list == undefined) { return null;};

                if(this.first_items == null){
                    this.first_items = JSON.parse(JSON.stringify(this.cPlanData.shifts.items.list));
                }else if(type == 1){ //dla refresh po getList()
                    //update cPlanData.shifts.items.list.department.zzp_preferences
                    this.first_items.forEach(item => {
                        item.department.zzp_preferences = this.cPlanData.shifts.items.list.find(e => e.id_temp == item.id_temp).department.zzp_preferences;
                    });
                    if (this.first_items.some(item => item.employment == null || item.employment.value == -1)) {
                        this.first_items.forEach(item => {
                            if (item.employment == null || item.employment.value == -1) {
                                item.employment = this.cPlanData.shifts.items.list.find(e => e.id_temp == item.id_temp).employment;
                            }
                        });
                    }
                    this.cPlanData.shifts.items.list = JSON.parse(JSON.stringify(this.first_items))
                }

                let local_items = this.cPlanData.shifts.items.list.filter(element => element.list_type == this.type);
                this.cPlanData.shifts.filters.sorting.field_name = this.cPlanData.shifts.filters.sorting.field_name == 'function' && this.type == 2 ? '' : this.cPlanData.shifts.filters.sorting.field_name;
                
                if (this.cPlanData.shifts.filters.sorting.field_name != null && this.cPlanData.shifts.filters.sorting.field_name != '') {
                    let sort_field = this.cPlanData.shifts.filters.sorting.field_name;
                    let sort_direction = this.cPlanData.shifts.filters.sorting.sort_direction;
                    let can_sort = this.cCanFunctionSort;

                    if (sort_field == 'department' || ( sort_field == 'function' && can_sort) || sort_field == 'date_from') { 
                         local_items.sort(function(a, b) {  
                        
                        let field_a = "";
                        let field_b = "";

                        if (sort_field == 'department') {
                            if (a.department == null) field_a = a.department_source; else field_a = a.department.name;
                            if (b.department == null) field_b = b.department_source; else field_b = b.department.name;            
                        } else if (sort_field == 'function') {
                            if (a.function == null) field_a = a.function_source; else field_a = a.function.label;
                            if (b.function == null) field_b = b.function_source; else field_b = b.function.label;
                        } else if (sort_field == 'date_from') {
                            if (a.date_from == null) field_a = a.date_from_source; else field_a = a.date_from;   
                            if (b.date_from == null) field_b = b.date_from_source; else field_b = b.date_from;                   
                        }

                        if (sort_direction == 1 && field_a) {
                            return field_b.localeCompare(field_a);
                        } else if(sort_direction != 1 && field_b){
                            return field_a.localeCompare(field_b);
                        }   
                            
                    });
                    }
                   
                }
                
                

                let begin = 0;
                let end = this.cPlanData.shifts.filters.per_page;

                if (this.cPlanData.shifts.filters.page > 1) {
                    begin = (this.cPlanData.shifts.filters.page - 1) * this.cPlanData.shifts.filters.per_page;
                    end = begin + this.cPlanData.shifts.filters.per_page;
                }

                this.items = { 
                    total: local_items.length,
                    total_done: this.cPlanData.shifts.items.list.filter(element => element.list_type == 1).length,
                    total_undone: this.cPlanData.shifts.items.list.filter(element => element.list_type == 2).length,
                    list: local_items.slice(begin, end),
                    refresh: this.items.refresh ? this.cPlanData.shifts.items.list.refresh : 0
                };
            
        },

        validateItem(itemId) {
            let items = this.cPlanData.shifts.items.list.filter(item => item.id_temp == itemId);
            if (items != null && items.length > 0) {
            
                let item = items[0];
                if (item.department != null && item.department.id_cao_data_main != null && 
                    item.function != null &&
                    item.date_from != null && (moment(item.date_from, 'DD-MM-YYYY').format('DD-MM-YYYY') !== moment().format('DD-MM-YYYY') && moment(item.date_from, 'DD-MM-YYYY').isBetween(moment().subtract(91, 'days'), moment().add(1, 'years')) || 
                    (moment(item.date_from, 'DD-MM-YYYY').format('DD-MM-YYYY') === moment().format('DD-MM-YYYY') && item.hour_from != null && moment(item.hour_from, 'hh:mm').isAfter(moment().subtract(1, 'hours'))) ) &&
                    item.hour_from != null && item.hour_to != null && item.qualifications.qualification_error == 0 && item.shift_type != null && item.shift_type.value
                    ) {
                        item.list_type = 1;
                    } else {
                        item.list_type = 2;
                }
            }
        },

        async calculateShiftType(element) {
            await this.$nextTick();
            this.validateItem(element.id_temp);
            element.shift_type = this.shift_type_class.calculate(element.hour_from, element.hour_to, true); 
            this.setItems();
        },

        reduceFunctions(department) {
            if (department != null && department.id_cao_data_main != null) {
                return this.functions.filter(item => item.id_cao_data_main == department.id_cao_data_main);
            }
        },

        async setFunction(selectedOption, selectedItem) { 
            await this.$nextTick();
            this.validateItem(selectedItem.id_temp);           
            if (selectedOption != null) {
                let sameFunctionItems = this.cPlanData.shifts.items.list.filter(item => item.function_name_source == selectedItem.function_name_source && item.function == null && item.department != null && item.department.id_cao_data_main != null);

                this.mAlert.args.sameFunctionItems = sameFunctionItems;
                this.mAlert.args.selectedOption = selectedOption;
                this.mAlert.args.selectedItem = selectedItem;
                this.mAlert.args.type = 2;
                this.mAlert.show = true;

            } else {
                 this.setItems();
            }
            this.is_row_clicked = false;
            this.fields[1].sortable = this.cCanFunctionSort;
        },

        async setPerson(id_temp){
            await this.$nextTick();
            this.validateItem(id_temp);   
            this.setItems(); 
        },

    async setDepartment(selectedOption, selectedItem) {
            await this.$nextTick();
            if(selectedItem.department && selectedItem.department.is_sleep_shift && selectedItem.department.is_sleep_shift == 0 && selectedItem.shift_type && selectedItem.shift_type.value == 6){
                selectedItem.shift_type = null;
            }
            this.validateItem(selectedItem.id_temp);
            if (selectedOption != null) {
                let sameDepartmentItems = this.cPlanData.shifts.items.list.filter(item => item.department_source == selectedItem.department_source && item.department == null);
                
                this.mAlert.args.sameDepartmentItems = sameDepartmentItems;
                this.mAlert.args.selectedOption = selectedOption;
                this.mAlert.args.selectedItem = selectedItem;
                this.mAlert.args.type = 1;
                this.mAlert.show = true;
       
                if(selectedOption.zzp_preferences != 1 && selectedOption.id_person_data_main !== null){
                    if((selectedItem.applicant_type == 3 || selectedItem.applicant_type == 4) && selectedOption.zzp_preferences == 2){
                        selectedItem.applicant_type = {name: selectedItem.applicant_type == 3 ? 'Student' : 'Doorleen', value: selectedItem.applicant_type};
                    }else{
                        selectedItem.applicant_type = {name: selectedOption.zzp_preferences == 2 ? 'UZK' : 'ZZP', value: selectedOption.zzp_preferences == 2 ? 1 : 2};
                    }
                }
            } else {
                 this.setItems();
            }  
        },

       checkFunction(selectedItem){
            let functions = this.reduceFunctions(selectedItem.department)
            let checked_function = null;
            var index = this.cPlanData.shifts.items.list.findIndex(item => item.id_temp == selectedItem.id_temp);
            functions.forEach((item) => {
                let aliases = [];
                if(item.name_alias && item.name_alias != null && item.name_alias != ""){
                    aliases = item.name_alias.split(',')
                }

                if(item.name.toLowerCase() == selectedItem.function_name_source.toLowerCase()){
                    checked_function = {name: item.name, value: item.id_function_data_main};
                    this.cPlanData.shifts.items.list[index].function = {name: item.name, id_function_data_main: item.id_function_data_main}
                    this.setItems();
                    return this.cPlanData.shifts.items.list[index].function;
                }else if(aliases.includes(selectedItem.function_name_source.toLowerCase())){
                    checked_function = {name: item.name, value: item.id_function_data_main}
                    this.cPlanData.shifts.items.list[index].function = {name: item.name, id_function_data_main: item.id_function_data_main}
                    this.setItems();
                }
            });
            if(checked_function == null){
                this.cPlanData.shifts.items.list[index].function = null;
            }
        },

        async setDate(selectedOption, selectedItem) {
            await this.$nextTick();
            this.validateItem(selectedItem.id_temp);
            this.setItems();
        },

        isDateValid(date_from, hour_from){
            this.isTimeValid(date_from, hour_from);
            return !moment(date_from, 'DD-MM-YYYY').isBetween(moment().subtract(1, 'days'), moment().add(1, 'years'))
        },

        isTimeValid(date_from, hour_from){
            if(moment(date_from, 'DD-MM-YYYY').format('DD-MM-YYYY') === moment().format('DD-MM-YYYY')){
                return !moment(hour_from, 'hh:mm').isAfter(moment().subtract(1, 'hours'));
            }else{
                return false;
            }
        },

        isInPast(date_from){

            if(moment(date_from, 'DD-MM-YYYY').format('yyyy-MM-DD') < moment().format('YYYY-MM-DD')){
                return true;
            }else{
                return false;
            }

        },

        setColumns() {
           if (this.cPlanData.columns.items.filter(item => item.type != null && item.type.value == 1).length <= 0) {
                this.fields[0].visible = false;
            }           
        },

        setDepartmentAlias(item){
            this.alertClass.openAlertConfirmation(this.trans('sh-change-aliases-confirmation',314)).then(res => {
                if(res == true) {
                    this.changeDepartmentAlias(item.department.value, item.department_source);
                } 
            });
        },

        setFunctionsAlias(item){
            this.alertClass.openAlertConfirmation(this.trans('sh-change-aliases-confirmation',314)).then(res => {
                if(res == true) {
                    this.changeFunctionAlias(item.function.id_function_data_main, item.function_name_source);
                } 
            });
        },

        async setDaypart(item){
            await this.$nextTick();
            this.validateItem(item.id_temp);
            this.setItems(); 
            
            if(item.shift_type_name_source && item.shift_type_name_source != ''){
                this.alertClass.openAlertConfirmation(this.trans('sh-change-aliases-confirmation',314)).then(res => {
                    if(res == true) {
                        this.changeDaytimeAlias(item.shift_type, item.shift_type_name_source)
                    } 
                });
            }
        },

        changeDaytimeAlias(id_shift_const_daytime, alias = null){
            this.mChangeDaytimeAliasesStep2.args.id_client_data_main = this.cPlanData.client.id_client_data_main;
            this.mChangeDaytimeAliasesStep2.args.id_shift_const_daytime = id_shift_const_daytime.value;
            this.mChangeDaytimeAliasesStep2.args.new_alias = alias != null ? alias : [];
            this.mChangeDaytimeAliasesStep2.show = true;
        },

        changeQualificationAliasStep1(ids_qualification_const_type){
            this.mChangeQualificationsAliasesStep1.args.ids_qualification_const_type = ids_qualification_const_type;
            this.mChangeQualificationsAliasesStep1.args.id_client_data_main = this.cPlanData.client.id_client_data_main;
            this.mChangeQualificationsAliasesStep1.show = true;
        },

        changeQualificationAliasStep2(item){
            this.mChangeQualificationsAliasesStep2.args.id_client_data_main = this.cPlanData.client.id_client_data_main;
            this.mChangeQualificationsAliasesStep2.args.id_qualification_const_type = item.id_qualification_const_type;
            this.mChangeQualificationsAliasesStep2.args.name_aliases = item.name_alias != null ? item.name_alias.split(',') : [];
            this.mChangeQualificationsAliasesStep2.args.filters = this.filters;
            this.mChangeQualificationsAliasesStep2.show = true;
        },

        async onRowClicked(item){
            if(item.department != null || item.function != null){
                this.is_row_clicked = true;
            }
            await this.$nextTick();
            
            if(this.$refs['vrDate'+item.id_temp] != undefined){
                this.$refs['vrDate'+item.id_temp].$refs.provider.validate();
            }
            
        },


        onCreateThis() { 
       
            //this.loadSettings();
            this.setColumns();
            this.getList();
            //this.saveSettings();
           
            this.$watch('cPlanData.shifts.filters.per_page', function(newVal, oldVal) {
                this.setItems();
                if(this.cPlanData.shifts.filters.page != 1 && this.cPlanData.shifts.items.total < this.cPlanData.shifts.filters.per_page)    {
                    this.cPlanData.shifts.filters.page = 1;
                } 
               
            });

            this.$watch('cPlanData.shifts.filters.page', function(newVal, oldVal) {
                this.setItems();        
            });

            this.$watch('items.refresh', function(newVal, oldVal) {
                if(!this.loadingList){
                    this.getList();
                }
            });

            this.$watch('cPlanData.shifts.filters.sorting.changed', function(newVal, oldVal) {
                this.setItems();               
            });

            this.$watch('type', function(newVal, oldVal){
                this.cPlanData.shifts.filters.sorting.field_name = "";
                this.fields.find(field => field.key == 'function').sortable = this.cCanFunctionSort;
            });

            this.$watch('mChangeQualificationsAliasesStep1.return', function(newVal, oldVal){
                if(newVal != null){
                    this.changeQualificationAliasStep2(newVal);
                }
            });
        },
    }

};
</script>

<style>
.app-local-row-center{
     text-align: center !important;
}

</style>

<style scoped>
.app-local-icon-employment{
    width: 15px;
    height: 15px;
}
</style>
