<template>
    <div>
        <b-overlay :show="loading" :opacity="0.6">
            
            <b-card>               

                <b-alert variant="light" show class="mb-0">
                    
                        <b-row  align-v="center">
                            <b-col md="8">
                                
                                <div class="d-flex align-items-center">
                                    <div>
                                        <b-icon icon="check-circle-fill" font-scale="3" class="mr-4"></b-icon>                        
                                    </div>

                                    <div class="w-100 align-items-center">           
                                        <span class="align-middle font-weight-600 text-base" v-bind:class="{ 'app-local-success': shifts.total_added_shifts == shifts.total_shifts, 'app-local-error': shifts.total_added_shifts != shifts.total_shifts}">{{trans('sh-added-services-to-total-shifts',314, {total_added_shifts : shifts.total_added_shifts, total_shifts : shifts.total_shifts  })}}</span><br/>
                                        <b-row>
                                            <b-col end sm="12" lg="auto">    
                                                <span class="app-local-person-data" v-if="data.import.level == 0"> {{data.office_location.name}} | {{data.client.name}}</span>
                                                <span class="app-local-person-data" v-if="data.import.level == 1"> {{data.office_location.name}} | {{data.client_location.name}}</span>
                                                <span class="app-local-person-data" v-if="data.import.level == 2"> {{data.office_location.name}} | {{data.client_location.name}} | {{data.client_department.name}} </span>
                                            </b-col>
                                        </b-row>
                                    </div>

                                </div>
                            </b-col>
                        </b-row>

                </b-alert>

            </b-card> 

            <b-tabs class="mt-3" justified>
                <b-tab :title="this.trans('sh-bulk-result-all',314,{count_shifts_all :shifts.total_shifts})" @click="showShifts(1)"></b-tab>          
                <b-tab :title="this.trans('sh-bulk-result-registered',314,{count_shifts_registered :count_shifts.registered})" @click="showShifts(2)"></b-tab> 
                <b-tab :title="this.trans('sh-bulk-result-interested',314,{count_shifts_interested :count_shifts.interested})" @click="showShifts(3)"></b-tab> 
                <b-tab :title="this.trans('sh-bulk-result-rejected',314,{count_shifts_rejected :count_shifts.rejected})" @click="showShifts(4)"></b-tab> 
            </b-tabs>
            <app-collapse accordion type="margin" v-if="show_shifts.register_people">
                <b-card no-body class="app-card-profile" v-for="item in show_shifts.register_people" :key="item.id_person_data_main" v-if="item.shifts.length > 0">
                    <app-collapse-item style="box-shadow:none !important;" :id="'person_'+item.person_name">
                        <template v-slot:header>
                            <span class="lead collapse-title">{{item.person_name}}</span>
                            <span class="text-muted ml-1"> <span class="app-local-person-data" v-bind:class="{ 'app-local-success': getAddedShiftByPerson(item.shifts) == item.shifts.length, 'app-local-error': getAddedShiftByPerson(item.shifts) != item.shifts.length}"> {{shifts_type == 1 ? '(Toegevoegd aan ' +getAddedShiftByPerson(item.shifts)+' van '+item.shifts.length+')' : '('+item.shifts.length+')' }}</span> </span>
                        </template>
                            
                        <b-row class="ml-1 mr-1 pb-2 mt-3 app-local-row-header-font">
                            <b-col sm="1"  md="1" class="pr-0 app-local-checkbox-all">
                                <app-check-box v-model="item.selected" value="1" unchecked-value="0" v-on:change="selectAll(item)" />
                            </b-col>
                            <b-col sm="10" md="10">
                                <b-row>
                                    <b-col cols="2">
                                       {{trans('pl-date',288)}}
                                    </b-col>
                                    <b-col cols="2">
                                        {{trans('hour',280)}}
                                    </b-col>
                                    <b-col cols="2">
                                        {{trans('sh-type-of-service',292)}}
                                    </b-col>
                                    <b-col cols="3">
                                        {{trans('flexworker',280)}}
                                    </b-col>
                                    <b-col cols="3">
                                        {{trans('detachment',280)}}
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col sm="1"  md="1" class="pr-0">
                            </b-col>
                        </b-row>

                        <b-list-group flush class="app-local-group-list mb-2" >

                            <b-list-group-item  v-for="shift in item.shifts" :key="shift.id_shift_data_main" class="app-local-group-item">
                                        
                                <b-row align-v="center">
                                    <b-col sm="1" md="1" class="pr-0">
                                        <app-check-box v-model="shift.selected" value="1" unchecked-value="0" v-on:change="selectedChanged(item, shift)"/>
                                    </b-col>

                                    <b-col sm="10" md="10">

                                        <b-row align-v="center">
                                            <b-col cols="2">
                                                {{shift.df}}
                                            </b-col>
                                            <b-col cols="2">
                                                {{shift.hf}} - {{shift.ht}}
                                            </b-col>
                                            <b-col cols="2">
                                                {{getDayTime(shift.id_sc)}}
                                            </b-col>
                                            <b-col cols="3" v-if="shift.id_p != null">
                                                    <div class="app-local-success" v-if="(shift.zzp_mediation_contract == 0 && shift.status >= 0 && shift.atw_status == 1) || (shift.zzp_mediation_contract == 1 && shift.status == 1 && shift.atw_status == 1)">{{trans('added',280)}}</div>
                                                    <div class="app-local-error" v-if="shift.status < 0 || shift.atw_status == 0">{{trans('sh-none-added',314)}}</div>
                                                    <div class="app-local-warning" v-if="(shift.zzp_mediation_contract == 1 && shift.status == 0 && shift.atw_status == 1) || (shift.opt_in == 1 && shift.status == 0 && shift.atw_status == 1)">{{trans(shift.error, 296)}}</div>
                                                    <div class="app-local-error" v-if="shift.status < 0">{{trans(shift.error, 296)}}</div>
                                                    <div class="app-local-error" v-if="shift.atw_status == 0 && shift.atw_error != null">
                                                        <span v-for="item_error in JSON.parse(shift.atw_error)">{{trans(item_error, 313)}}</span>
                                                    </div>
                                            </b-col>
                                            <b-col cols="3" v-if="shift.shift != null && shift.shift.assignment != null">
                                                {{shift.shift.assignment.details}}
                                            </b-col>
                                        </b-row>
                                    </b-col>

                                    <b-col sm="1" md="1" class="text-right">
                                        
                                        <b-dropdown variant="link"  no-caret right>
                                                <template #button-content>
                                                    <b-icon icon="three-dots-vertical" class="text-body" ></b-icon>
                                                </template>
                                                
                                                <b-dropdown-item @click="cancel([shift.id_sh], item.id_person_data_main)">{{trans('sh-cancel-services',314)}}</b-dropdown-item>

                                            </b-dropdown> 
                                    </b-col>

                                </b-row>

                            </b-list-group-item>

                        </b-list-group>

                        <b-dropdown no-caret toggle-class="app-button-multi" variant="secondary-grey" v-if="item.ids_shift_to_remove.length > 0">
                            <template #button-content>
                               {{trans('action',314)}} ({{item.ids_shift_to_remove.length}})                         
                            </template>
                                
                            <b-dropdown-item @click="cancel(item.ids_shift_to_remove, item.id_person_data_main)">{{trans('sh-cancel-services',314)}}</b-dropdown-item>                             
                            
                        </b-dropdown>

                    </app-collapse-item>
                </b-card>
                <b-card no-body class="app-card-profile" v-if="are_shifts == 0">
                    <span class="app-local-center">Er zijn geen records om weer te geven</span>
                </b-card>
            </app-collapse>


            <app-collapse accordion type="margin" class="mt-3" v-if="show_shifts.shifts_without_people.length > 0">
                <b-card no-body class="app-card-profile">
                    <app-collapse-item style="box-shadow:none !important;" :id="'shifts-without-people'">
                        <template v-slot:header>
                            
                            <span class="lead collapse-title">{{trans('sh-shifts-without-fw',314)}}</span>
                            <span class="text-muted ml-1"> <span class="app-local-person-data app-local-success">{{trans('sh-shifts-without-ppl',215,{shifts_without_people : show_shifts.shifts_without_people.length, total_shifts : show_shifts.total_shifts })}} </span> </span>

                        </template>
                            
                        <b-row class="ml-1 mr-1 pb-2 mt-3 app-local-row-header-font">
                            <b-col sm="1"  md="1" class="pr-0 app-local-checkbox-all">
                                <app-check-box v-model="selectedAllWithOutPeople" value="1" unchecked-value="0" v-on:change="selectAllWithOutPeople" />
                            </b-col>
                            <b-col sm="10" md="10">
                                <b-row>
                                    <b-col cols="4">
                                       {{trans('pl-date',288)}}
                                    </b-col>
                                    <b-col cols="4">
                                        {{trans('hour',280)}}
                                    </b-col>
                                    <b-col cols="4">
                                        {{trans('sh-type-of-service',292)}}
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col sm="1"  md="1" class="pr-0">
                            </b-col>
                        </b-row>

                        <b-list-group flush class="app-local-group-list mb-2" >

                            <b-list-group-item  v-for="shift in show_shifts.shifts_without_people" :key="shift.id_sh" class="app-local-group-item">
                                        
                                <b-row align-v="center">
                                    <b-col sm="1" md="1" class="pr-0">
                                        <app-check-box v-model="shift.selected" value="1" unchecked-value="0" v-on:change="selectedChangedWithOutPeople(shift)"/>
                                    </b-col>

                                    <b-col sm="10" md="10">

                                        <b-row align-v="center">
                                            <b-col cols="4">
                                                {{shift.df}}
                                            </b-col>
                                            <b-col cols="4">
                                                {{shift.hf}} - {{shift.ht}}
                                            </b-col>
                                            <b-col cols="4">
                                                {{getDayTime(shift.id_sc)}}
                                            </b-col>
                                        </b-row>
                                    </b-col>

                                    <b-col sm="1" md="1" class="text-right">
                                        
                                        <b-dropdown variant="link"  no-caret right>
                                                <template #button-content>
                                                    <b-icon icon="three-dots-vertical" class="text-body" ></b-icon>
                                                </template>
                                                
                                                <b-dropdown-item @click="cancel([shift.id_sh], null)">{{trans('sh-cancel-services',314)}}</b-dropdown-item>

                                            </b-dropdown> 
                                    </b-col>

                                </b-row>

                            </b-list-group-item>

                        </b-list-group>

                        <b-dropdown no-caret toggle-class="app-button-multi" variant="secondary-grey" v-if="selectedIdsWithOutPeople.length > 0">
                            <template #button-content>
                                {{trans('action',314)}} ({{selectedIdsWithOutPeople.length}})                         
                            </template>
                                
                            <b-dropdown-item @click="cancel(null)">{{trans('sh-cancel-services',314)}}</b-dropdown-item>                             
                            
                        </b-dropdown>

                    </app-collapse-item>
                </b-card>
            </app-collapse>

            <template #overlay>
                <div></div>
            </template>
        </b-overlay>    

        <offers-shifts-list-cancel-reason v-if="mShiftCancelReason.show" :show.sync="mShiftCancelReason.show" :args.sync="mShiftCancelReason.args" :result.sync="mShiftCancelReason.result" :return.sync="mShiftCancelReason.return" />
        
    </div>
</template>
<script>
import axios from "@axios";
import moment from 'moment';
import AppAlerts from '@core/scripts/AppAlerts';
import AppCollapse from "@core/components/AppCollapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/AppCollapse/AppCollapseItem.vue";
import OffersShiftsListCancelReason from '@app/Planning/Offers/Shifts/OffersShiftsCancelReason.vue';

export default {
    components: {
        AppCollapse,
        AppCollapseItem,
        OffersShiftsListCancelReason
    },

    props:["data", "shifts"],

    created(){
        this.showShifts(1);
        this.countShifts()
    },

    watch: {
        "mShiftCancelReason.result": {
            handler: function(val){
                if(this.mShiftCancelReason.result == 1){
                    this.loading = true;

                    if(this.mShiftCancelReason.args.id_person_data_main != null){//with FW

                        var tmpArray = this.shifts.register_people[this.mShiftCancelReason.args.id_person_data_main].shifts;

                        this.mShiftCancelReason.args.ids_shift_data_main.forEach((item) => {

                            for(var i=0; i<tmpArray.length; i++){
                                if(item == tmpArray[i].id_sh){
                                    tmpArray.splice(i, 1);
                                    break;
                                }
                            }

                        });

                        this.cShifts.register_people[this.mShiftCancelReason.args.id_person_data_main].shifts = tmpArray;

                    }else{//without FW

                        var tmpArray = this.shifts.shifts_without_people;

                        this.mShiftCancelReason.args.ids_shift_data_main.forEach((item) => {

                            for(var i=0; i<tmpArray.length; i++){
                                if(item == tmpArray[i].id_sh){
                                    tmpArray.splice(i, 1);
                                    break;
                                }
                            }

                        });

                        this.cShifts.shifts_without_people = tmpArray;
                        this.selectedIdsWithOutPeople = [];
                        this.selectedAllWithOutPeople = 0; 

                    }     
                    
                    //remove from shifts
                    var tmpArray = this.shifts.shifts;
                    this.mShiftCancelReason.args.ids_shift_data_main.forEach((item) => {

                        for(var i=0; i<tmpArray.length; i++){
                            if(item == tmpArray[i].id_sh){
                                tmpArray.splice(i, 1);
                                break;
                            }
                        }

                    });

                    this.cShifts.shifts = tmpArray;
                    this.cShifts.total_added_shifts = this.shifts.total_added_shifts - this.mShiftCancelReason.args.ids_shift_data_main.length;
                    this.loading = false;
                }

                this.countShifts();
                this.showShifts(this.shifts_type)
            }
        }
    },

    computed: {
        cShifts: {
            get() {return this.shifts },
            set(value) { this.$emit('update:shifts', value) }
        }
    },

    data() {
        return {
            show_shifts: JSON.parse(JSON.stringify(this.shifts)),
            shifts_type: 1,
            loading: false,
            are_shifts:0,
            count_shifts:{
                registered:0,
                interested:0,
                rejected:0
            },
            selectedAllWithOutPeople: "0",
            selectedIds: [],
            selectedIdsWithOutPeople: [],
            alertClass: new AppAlerts(),
            function_name: "",
            mShiftCancelReason: { show: false, args: {
                                                        ids_shift_data_main: null, 
                                                        id_person_data_main: null,
                                                        is_shift_done: null  
                                                     },
                                 result: 0, return: null},
        };
    },

    methods: {
        getDayTime(idShiftConstDaytime){

            if(idShiftConstDaytime == 1){
                return this.trans('day-part-morning',280);
            }else if(idShiftConstDaytime == 2){
                return this.trans('day-part-afternoon',280);
            }else if(idShiftConstDaytime == 3){
                return this.trans('day-part-day',280);
            }else if(idShiftConstDaytime == 4){
                return this.trans('day-part-evening',280);
            }else if(idShiftConstDaytime == 5){
                return this.trans('day-part-night',280);
            }else if(idShiftConstDaytime == 6){
                return this.trans('day-part-sleeping-shift',280);
            }

        },

        setFormatDate(dateValue) {
           return moment(dateValue).format('DD-MM-YYYY');
        },
        //

        getAddedShiftByPerson(shifts){
            
            if(shifts != null){
                let counter = 0;

                shifts.forEach(item => {
                    if(item.status >= 0 && item.atw_status == 1){
                        counter++;
                    }
                });
                
                return counter;
            }

            return 0;
        },

        selectAll(item){
            
            if(item.selected == 0){
                this.shifts.register_people[item.id_person_data_main].shifts.forEach(element => {
                    element.selected = "0";
                    item.ids_shift_to_remove.splice(item.ids_shift_to_remove.indexOf(element.id_sh), 1);
                });

            } else {
                item.ids_shift_to_remove = [];
                this.shifts.register_people[item.id_person_data_main].shifts.forEach(element => {
                    element.selected = "1";
                    item.ids_shift_to_remove.push(element.id_sh);
                });
            }

        },


        selectAllWithOutPeople(){
            if(this.selectedAllWithOutPeople == 0){
                this.shifts.shifts_without_people.forEach(element => {
                    element.selected = "0";
                    this.selectedIdsWithOutPeople.splice(this.selectedIdsWithOutPeople.indexOf(element.id_sh), 1);
                });

            } else {
                this.selectedIdsWithOutPeople = [];
                this.shifts.shifts_without_people.forEach(element => {
                    element.selected = "1";
                    this.selectedIdsWithOutPeople.push(element.id_sh);
                });
            }
        },

        selectedChanged(item, shift){
            if(shift.selected == "1"){
                item.ids_shift_to_remove.push(shift.id_sh);
            }
            else if(shift.selected == "0") {
                item.ids_shift_to_remove.splice( item.ids_shift_to_remove.indexOf(shift.id_sh), 1);
            }

            if(item.selected == 1){
                this.shifts.register_people[item.id_person_data_main].shifts.forEach(element => {
                    if(element.selected == "0"){
                        item.selected = 0;
                    }
                });
            }

            if(item.selected == 0){
                var counter = 0;

                this.shifts.register_people[item.id_person_data_main].shifts.forEach(element => {
                    if(element.selected == "1"){
                        counter++;
                    }
                });

                if(counter == this.shifts.register_people[item.id_person_data_main].shifts.length){
                    item.selected = 1;
                }
            }
        },

        showShifts(type){
            this.shifts_type = type;
            this.show_shifts = JSON.parse(JSON.stringify(this.shifts));
            this.are_shifts = 0;
            for (let element in this.shifts.register_people) {
                if(type == 2){
                    this.show_shifts.register_people[element].shifts = this.shifts.register_people[element].shifts.filter(item => (item.zzp_mediation_contract == 0 && item.status >= 0 && item.atw_status == 1) || (item.zzp_mediation_contract == 1 && item.status == 1 && item.atw_status == 1))
                    this.are_shifts += this.show_shifts.register_people[element].shifts.length;
                }else if(type == 4){
                    this.show_shifts.register_people[element].shifts = this.shifts.register_people[element].shifts.filter(item => (item.status < 0 || item.atw_status != 1))
                    this.are_shifts += this.show_shifts.register_people[element].shifts.length;
                }else if(type == 3){
                    this.show_shifts.register_people[element].shifts = this.shifts.register_people[element].shifts.filter(item => (item.zzp_mediation_contract == 1 && item.status == 0 && item.atw_status == 1) || (item.opt_in == 1 && item.status == 0 && item.atw_status == 1))
                    this.are_shifts += this.show_shifts.register_people[element].shifts.length;
                }else{
                    this.are_shifts += this.show_shifts.register_people[element].shifts.length;
                }
            };
        },

        countShifts(){
            this.count_shifts.registered = 0;
            this.count_shifts.rejected = 0;
            this.count_shifts.interested = 0;

            for (let element in this.shifts.register_people) {

                this.count_shifts.registered += this.shifts.register_people[element].shifts.filter(item => (item.zzp_mediation_contract == 0 && item.status >= 0 && item.atw_status == 1) || (item.zzp_mediation_contract == 1 && item.status == 1 && item.atw_status == 1)).length

                this.count_shifts.rejected += this.shifts.register_people[element].shifts.filter(item => (item.status < 0 || item.atw_status != 1)).length

                this.count_shifts.interested += this.shifts.register_people[element].shifts.filter(item => (item.zzp_mediation_contract == 1 && item.status == 0 && item.atw_status == 1) || (item.opt_in == 1 && item.status == 0 && item.atw_status == 1)).length

            }
        },

        selectedChangedWithOutPeople(item){

            if(item.selected == "1"){
                this.selectedIdsWithOutPeople.push(item.id_sh);
            }
            else if(item.selected == "0") {
                this.selectedIdsWithOutPeople.splice(this.selectedIdsWithOutPeople.indexOf(item.id_sh), 1);
            }

            if(this.selectedAllWithOutPeople == 1){
                this.shifts.shifts_without_people.forEach(element => {
                    if(element.selected == "0"){
                        this.selectedAllWithOutPeople = 0;
                    }
                });
            }

            if(this.selectedAllWithOutPeople == 0){
                var counter = 0;

                this.shifts.shifts_without_people.forEach(element => {
                    if(element.selected == "1"){
                        counter++;
                    }
                });

                if(counter == this.shifts.length){
                    this.selectedAllWithOutPeople = 1;
                }
            }
        },

        openShift(item){
            let routeData = this.$router.resolve({name: 'planning-shifts-profile', params: { id_shift_data_main: item.id_sh }});
            window.open(routeData.href, '_blank');
        },


        cancel(ids_shift_data_main, id_person_data_main = null){

            this.alertClass.openAlertConfirmation(this.trans('cancel-confirmation',281)).then(res => {
            
                if(res == true){
                    this.mShiftCancelReason.args.id_person_data_main = id_person_data_main;

                    if(ids_shift_data_main == null && id_person_data_main == null){
                        ids_shift_data_main = this.selectedIdsWithOutPeople;
                    }

                    this.mShiftCancelReason.args.is_shift_done = 0;
                    this.mShiftCancelReason.args.ids_shift_data_main = ids_shift_data_main;
                    this.mShiftCancelReason.result = 0;
                    this.mShiftCancelReason.show = true;
                }

            });

        },

    }

};
</script>

<style scoped>

.app-local-person-data{
    font-size: 14px;
}

.custom-avatar{
    height: 52px;
    width: 52px;
}

.app-local-group-list{
    border-bottom: 2px solid rgba(0, 0, 0, 0.05);
}

.app-local-group-item{
    padding: 0.5rem 1rem;
    color: #174066;
    font-weight: 300;
    font-size: 14px;
}

.app-local-group-list:hover{
    background-color: #f6f9fc;
    z-index: auto;
    text-decoration: none;
    cursor: pointer;
}

.app-local-row-header-font {
    font-weight: 600;
    color: #1f627f;
    font-size: 14px;
    text-transform: none;
    letter-spacing: 1px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.05);
}

.app-local-checkbox-all{
    padding-left: 12px;
}

.app-local-error{
    color: #d92d20;
}

.app-local-success{
    color: #12B76A;
}

.app-local-warning{
      color: orange;
}
.app-local-center{
    text-align: center;
}

</style>
