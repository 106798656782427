<template>
    <div>
       <b-row>
            <b-col :lg="boxSize.lg" :xl="boxSize.xl" :offset-xl="boxSize.offsetXl" :offset-lg="boxSize.offsetLg"> 
                <div class="mt-3 mb-2 d-flex align-items-center">
                    <b-icon icon="arrow-left" class="mr-2" v-if="data.form_step < 3"></b-icon>
                    <b-link v-if="data.form_step == 2" href="#" @click="goBack()" class="d-flex align-items-center">{{trans('previous-step',280)}}</b-link>
                    <b-link href="#" @click="toPlanning" v-if="data.form_step == 1">{{trans('to-job-offer',280)}}</b-link>
                </div>
                <h1 class="mt-2 mb-4" v-if="data.form_step == 1">{{trans('sh-create-bulk-service',314)}}</h1>
                <ValidationObserver ref="form">

                    <div class="mb-3" v-if="data.form_step == 1">              
                       <offers-shifts-add-bulk-import-data-component :data.sync="data" :loadingBasicInformations="loading_basic_informations"/>
                    </div>

                    <div class="mb-3" v-if="data.form_step == 1 && cImportValidation == true">              
                       <offers-shifts-add-bulk-import-config-component :data.sync="data" :configData.sync="config_data"/>
                    </div>

                    <div class="mb-3" v-if="data.form_step == 2">              
                       <offers-shifts-add-bulk-check-list-component :planData.sync="data"/>
                    </div>

                    <div class="mb-3" v-if="data.form_step == 3">
                        <offers-shifts-add-bulk-result-list-component :data.sync="data" :shifts.sync="added_shifts"/>
                    </div>
                    
                </ValidationObserver> 
                <div class="mt-4 mb-3" >
                    <app-button type="primary" :loading="loadingAddAndClose || loading_basic_informations" v-if="data.form_step == 1" @click="goForward()">
                        {{trans('next-step',280)}}
                    </app-button>
                    <app-button type="primary" :loading="loadingAddAndClose" v-if="data.form_step == 2" @click="add" :disabled="!cShiftsValidated">
                        {{trans('sh-create-shift',292)}}
                    </app-button>

                    <app-button type="primary" @click="toPlanning()" v-if="data.form_step == 3">
                        {{trans('to-job-offer',280)}}
                    </app-button>

                    <app-button type="primary" @click="getCSV()" v-if="data.form_step == 3">
                        {{trans('generate-csv',280)}}
                    </app-button>
                </div>
             </b-col>
       </b-row>
    </div>
</template>
<script>

import axios from "@axios";
import moment from "moment";
import { ValidationObserver } from "vee-validate";
import OffersShiftsAddBulkImportDataComponent from './ComponentsBulk/Step1/OffersShiftsAddBulkImportDataComponent.vue';
import OffersShiftsAddBulkImportConfigComponent from './ComponentsBulk/Step1/OffersShiftsAddBulkImportConfigComponent.vue';
import OffersShiftsAddBulkCheckListComponent from './ComponentsBulk/OffersShiftsAddBulkCheckListComponent.vue';
import OffersShiftsAddBulkResultListComponent from './ComponentsBulk/OffersShiftsAddBulkResultListComponent.vue';
import AppAlerts from '@core/scripts/AppAlerts';

export default {
    components: {
        ValidationObserver,
        OffersShiftsAddBulkImportDataComponent,
        OffersShiftsAddBulkImportConfigComponent,
        OffersShiftsAddBulkCheckListComponent,
        OffersShiftsAddBulkResultListComponent
    },

    created(){
        this.getOfficeLocationForDepartmentSelection();
    },

    computed : {
        cImportValidation : {
            get() {
                if (this.data.import.items.length > 0) {
                    return true;
                } else {
                    return false;
                }
            }
        },

        cShiftsValidated : {
            get() { 
                if (this.data.shifts.items.length == 0 || (this.data.shifts.items.length > 0 && this.data.shifts.items.list.filter(element => element.list_type == 2).length > 0)) {
                    return false;
                } else {
                    return true;
                }
            }
        },

         cProcedure : {
            get() {
                if (this.data.import.level == 0 && this.data.client != null) return this.data.client.import_procedure;
                else return null;
            }
        },
    },

    watch: {
        "data.shifts.items": {
            handler: function(val) {
                if(this.data.shifts.items.list){
                    for(var i=0;i<this.data.shifts.items.list.length;i++){
                        if(this.data.import.level < 2 && (!this.data.shifts.items.list[i].department || !this.data.shifts.items.list[i].function || !this.data.shifts.items.list[i].date_from || !moment(this.data.shifts.items.list[i].date_from, 'DD-MM-YYYY').isBetween(moment().subtract(91, 'days'), moment().add(1, 'years')) || !this.data.shifts.items.list[i].hour_from || !this.data.shifts.items.list[i].hour_to || !this.data.shifts.items.list[i].shift_type)){
                            this.data.in_past_error = false;
                            this.data.import_error = true;
                        }else if(moment(this.data.shifts.items.list[i].date_from, 'DD-MM-YYYY').format('yyyy-MM-DD') < moment().format('YYYY-MM-DD') && this.data.shifts.items.list[i].id_person_data_main == null){
                            this.data.in_past_error = true;
                            this.data.import_error = false;
                        }else{
                            this.data.import_error = false;
                            this.data.in_past_error = false;
                        }
                    }
                }
            },
            deep: true
        }
    },

    data() {
        return {
            alertClass: new AppAlerts(),

            boxSize:{
                lg:10,
                xl:8,
                offsetXl:2,
                offsetLg:1,
            },

            data : {
                form_step: 1,
                validation_configuration: false,
                selected_schema: null,
                all_user_office: 0,
                id_office_data_source: null,

                office_location: {name: JSON.parse(localStorage.getItem('user-portal')).office_data_location, value: JSON.parse(localStorage.getItem('user-portal')).id_office_data_location},
                client: {name: JSON.parse(localStorage.getItem('user-portal')).client_name, value: JSON.parse(localStorage.getItem('user-portal')).id_client_data_main, import_procedure: '0'},
                client_location: null,
                client_department: null,

                import: {
                    items: [],
                    source: null,  // 1 file, 2 - clipboard
                    procedure: JSON.parse(localStorage.getItem('user-portal')).import_procedure, // 1 - ndividual algorithm, 2 - own column configuration 
                    level: 0, // 2 - department, 1 - location, 0 - client

                },

                columns: {
                    items: [],
                    headers: false,
                    refresh: 0
                },

                shifts: {

                    items: [],
                    filters: { 

                                sorting: {
                                            field_name: null,
                                            sort_direction: 0,
                                            changed: 0
                                        },
                                page: 1,
                                per_page: 10,
                                search: "",
                                list_type: 1,
                                changed: 0,
                            },
                    
                },   
                
                users_args: {
                    id_location_data_main: null, 
                    id_office_data_location:null
                },
                import_error: false,
                last_schema:[],
                in_past_error: false,
                qualifications_error: 0,

            },
            config_data:{
                schemas: [],
                basic_informations: [],
            },
            loadingAddAndClose: false,
            loadingAdd: false,
            loading_basic_informations:false,

            added_shifts: null,
        };
    },

    methods: {
            async add() {
                this.loadingAddAndClose = true;
                var id_client_data_main = null;
                var id_client_data_location = null;
                var tmp = [];
                this.data.qualifications_error = 0;
                
                if (this.data.import.level == 0 && this.data.client != null) { // client
                    id_client_data_main = this.data.client.value;
                    //id_client_data_location = this.data.client.id_client_data_location;
                }

                for(var i=0;i<this.data.shifts.items.list.length;i++){
                    if(this.data.import.level < 2 && (!this.data.shifts.items.list[i].department || !this.data.shifts.items.list[i].function || !this.data.shifts.items.list[i].date_from || !moment(this.data.shifts.items.list[i].date_from, 'DD-MM-YYYY').isBetween(moment().subtract(91, 'days'), moment().add(1, 'years')) || !this.data.shifts.items.list[i].hour_from || !this.data.shifts.items.list[i].hour_to || !this.data.shifts.items.list[i].shift_type)){
                        this.loadingAddAndClose = false
                        this.data.in_past_error = false;
                        return this.data.import_error = true;
                    }else if(moment(this.data.shifts.items.list[i].date_from, 'DD-MM-YYYY').format('yyyy-MM-DD') < moment().format('YYYY-MM-DD') && this.data.shifts.items.list[i].id_person_data_main == null){
                        this.loadingAddAndClose = false;
                        this.data.import_error = false;
                        return this.data.in_past_error = true;
                    }else if(this.data.shifts.items.list[i].qualifications.non_matched_aliases.length > 0){
                        this.data.qualifications_error = 1;
                    }else{
                        this.data.import_error = false;
                        this.data.in_past_error = false;
                    }
                }

                if(this.data.qualifications_error == 1){
                    await this.alertClass.openAlertConfirmationShiftsQualification(this.trans('pl-scheduler-qualifications-warning',291)).then(res => {
                        if(res == true) {
                            this.data.qualifications_error = 0
                        }else{
                            this.data.qualifications_error = 1
                            this.loadingAddAndClose = false;
                        }
                    });
                }

                if(this.data.qualifications_error == 0){
                    this.data.shifts.items.list.forEach((item) => {
                        tmp.push({"id_d": item.department.id_department_data_main,
                                "id_c": item.department.id_cao_data_main,
                                "id_f": item.function.id_function_data_main,
                                "id_cl": item.department.id_client_data_location,
                                "id_l": item.department.id_location_data_main,
                                "id_o": item.department.id_office_data_location,
                                "df": item.date_from,
                                "hf": item.hour_from,
                                "ht": item.hour_to,
                                "d": item.description,
                                "c_s": item.department.cao_source,
                                "id_sc": item.shift_type.value,
                                "f_a": item.function.ids_function_data_additional && item.function.ids_function_data_additional != '' ? item.function.ids_function_data_additional.toString().split(`,`).map(x=>+x) : [],
                                "id_p": item.id_person_data_main,
                                "p_n": item.person_name,
                                "f_n": item.function.name,
                                "d_n": item.department.name,
                                "sc":item.shift_type.name,
                                "all_user_office": this.data.all_user_office,
                                "id_office_data_source": this.data.all_user_office == 1 && this.data.id_office_data_source != null ? this.data.id_office_data_source.value : null,
                                "pause_type": item.pause && item.pause.value ? item.pause.value : 0,
                                "shift_code": item.shift_code ? item.shift_code : '',
                                "selected": '0',
                                'ids_qualification_const_type': item.qualifications.ids_qualification_const_type,
                                'qualification_names': item.qualifications.qualification_names,
                                'id_employment_const_type': item.employment && item.employment.value ? item.employment.value : item.department.zzp_preferences,
                                'applicant_type': item.applicant_type && item.applicant_type.value ? item.applicant_type.value : null
                                });
                    });
                    axios
                            .post("shifts/addBulk", {
                                shifts: JSON.stringify(tmp),
                                id_client_data_main: id_client_data_main,
                                id_client_data_location: id_client_data_location
                                
                            })
                            .then((res) => {
                                
                                this.added_shifts = res.data;
                                this.boxSize.lg = 10;
                                this.boxSize.xl = 8;
                                this.boxSize.offsetXl = 2;
                                this.boxSize.offsetLg = 1;
                                this.data.form_step = 3;
                            })
                            .catch((error) => {
                                console.error(`error during request: ${error}`);
                            }).finally(() => {
                                this.loadingAddAndClose = false;
                            });

                }

        },

        goForward() {
           
            this.$refs.form.validate().then((success) => {
                if (success && this.validateColumns() == true) {
                    if(this.data.form_step == 1 && this.data.selected_schema){
                        this.data.last_schema = {
                            'import_schema': JSON.stringify(this.data.columns.items),
                            'with_headers': this.data.columns.headers,
                            'name': this.data.selected_schema.name
                        }
                    }else if(this.data.form_step == 1){
                        this.data.last_schema = {
                            'import_schema': JSON.stringify(this.data.columns.items),
                            'with_headers': this.data.columns.headers,
                            'name': null
                        }
                    }
                    this.data.form_step = 2;
                    this.boxSize.lg = 12;
                    this.boxSize.xl = 12;
                    this.boxSize.offsetXl = 0;
                    this.boxSize.offsetLg = 0;
                }
            });
        },

        goBack(){
            this.data.form_step = 1;
            this.boxSize.lg = 10;
            this.boxSize.xl = 8;
            this.boxSize.offsetXl = 2;
            this.boxSize.offsetLg = 1;
        },

        getOfficeLocationForDepartmentSelection(){
            this.loading_basic_informations = true;

            axios
                .post("shifts/getOfficeLocationForDepartmentSelection", {
                    id_office_data_location: this.data.office_location.value, 
                    id_client_data_main: this.data.client.value
                })
                .then((res) => {
                    this.config_data.basic_informations = res.data;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                }).finally(() => {
                    this.getClientAndSchemaData();
                });

        },

        getClientAndSchemaData(){
            const unique_clients = [...new Map(this.config_data.basic_informations.map(item =>
                                [item['id_client_data_main'], item])).values()];
            
            for(var i=0;i<unique_clients.length;i++){
                this.data.client ={'name': unique_clients[i].client_name, 
                                        'id_client_data_main': unique_clients[i].id_client_data_main,
                                        'value': unique_clients[i].id_client_data_main,
                                        'import_procedure': unique_clients[i].import_procedure,
                                        'id_client_data_location': unique_clients[i].id_client_data_location,
                                        'id_office_data_location': unique_clients[i].id_office_data_location,
                                        'office_data_location_name': unique_clients[i].office_data_location_name,
                                        'student_recruitment': unique_clients[i].student_recruitment,
                                        'external': unique_clients[i].external }
            }

            this.config_data.schemas = this.config_data.basic_informations
                                    .filter(x => x.id_client_data_main == this.data.client.value )
                                    .map((x)=>{
                                        var a = {};
                                        a.name = x.schema_name;
                                        a.value = x.schema_value;
                                        a.import_schema = x.import_schema;
                                        a.with_headers = x.with_headers;
                                        return a;
                                    });
            this.config_data.schemas = [...new Map(this.config_data.schemas.map(item =>
                                    [item['value'], item])).values()].filter(x => x.value != null);
            this.config_data.schemas.sort((a,b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0))

        
            this.loading_basic_informations = false;
        },

        toPlanning(){
                       
            this.$router.push({ name: "planning-offers-shifts"});
        },
    

        getCSV(){

            Object.values(this.added_shifts.register_people).forEach(person => {
                person.shifts.forEach(shift => {
                    var temp_shift = this.added_shifts.shifts.find(x=> x.id_sh == shift.id_sh);
                    temp_shift.person = person.person_name;
                    temp_shift.registration = shift.status == 1 ? this.trans('yes-option',280) : this.trans('no-option',280);
                })
                
            });

            var headers= [];

            if(this.added_shifts.shifts.filter(e => e.id_sh != null).length > 0){
                headers.push('ID');
            }
            if(this.added_shifts.shifts.filter(e => e.d_n != null).length > 0){
                headers.push(this.trans('department',280));
            }
            if(this.added_shifts.shifts.filter(e => e.f_n != null).length > 0){
                headers.push(this.trans('sh-function',292));
            }
            if(this.added_shifts.shifts.filter(e => e.df != null).length > 0){
                headers.push(this.trans('pl-date',288));
            }
            if(this.added_shifts.shifts.filter(e => e.hf != null).length > 0){
                headers.push(this.trans('sh-begin',292));
            }
            if(this.added_shifts.shifts.filter(e => e.ht != null).length > 0){
                headers.push(this.trans('sh-end',292));
            }
            if(this.added_shifts.shifts.filter(e => e.sc != null).length > 0){
                headers.push(this.trans('sh-type-of-service',292));
            }
            if(this.added_shifts.shifts.filter(e => e.fa != null).length > 0){
                headers.push(this.trans('deviant-funtions',314));
            }

            if(this.added_shifts.shifts.filter(e => e.person != null).length > 0){
                headers.push(this.trans('flexworker',280));
            }  

            headers.push(this.trans('registered',288));

            if(this.added_shifts.shifts.filter(e => e.d != null).length > 0){
                headers.push(this.trans('sh-description-label',314));
            }

            if(this.added_shifts.shifts.filter(e => e.pause_type != null).length > 0){
                headers.push(this.trans('h-pause',305));
            }

            if(this.added_shifts.shifts.filter(e => e.qualification_names != null).length > 0){
                headers.push(this.trans('kwalifications', 283));
            }

            if(this.added_shifts.shifts.filter(e => e.shift_code != null).length > 0){
                headers.push(this.trans('sh-shift-code',292));
            }

            if(this.added_shifts.shifts.filter(e => e.id_employment_const_type != null)){
                headers.push(this.trans('planning-employment', 288));
            }

            var res =this.added_shifts.shifts.map((o) =>{
                var res = [];
                if(headers.includes('ID')){
                    res.push(o.id_sh);
                }
                if(headers.includes(this.trans('h-department',305))){
                    res.push(o.d_n);
                }
                if(headers.includes(this.trans('sh-function',292))){
                    res.push(o.f_n);
                }
                if(headers.includes(this.trans('pl-date',288))){
                    res.push(o.df);
                }
                if(headers.includes(this.trans('sh-begin',292))){
                    res.push(o.hf);
                }
                if(headers.includes(this.trans('sh-end',292))){
                    res.push(o.ht);
                }
                if(headers.includes(this.trans('sh-type-of-service',292))){
                    res.push(o.sc);
                }
                if(headers.includes(this.trans('deviant-funtions',314))){
                    res.push(o.fa);
                }
               
                if(headers.includes(this.trans('flexworker',280))){
                    res.push(o.person);
                }

                if(headers.includes(this.trans('registered',288))){
                    res.push(o.registration ? o.registration : '');
                }

                if(headers.includes(this.trans('description',280))){
                    res.push(o.d);
                }

                if(headers.includes(this.trans('h-pause',305))){
                    res.push(o.pause_type == 0 ? '' : (o.pause_type == 1 ? this.trans('no-option',280) : this.trans('yes-option',280)));
                }

                if(headers.includes(this.trans('kwalifications', 283))){
                    res.push(o.qualification_names);
                }

                if(headers.includes(this.trans('sh-shift-code',292))){
                    res.push(o.shift_code);
                }

                if(headers.includes(this.trans('planning-employment', 288))){
                    switch(parseInt(o.id_employment_const_type)){
                        case 1:
                            res.push(this.trans('sh-uzk-zzp',292));
                            break;
                        case 2:
                            res.push(this.trans('sh-uzk',292));
                            break;
                        case 3:
                            res.push(this.trans('sh-zzp',292));
                            break;
                        default:
                            res.push('');
                    }
                }
                return res;
                
            });

            res.unshift(headers)

            let csvContent = "data:text/csv;charset=utf-8," + res.map(e => e.join(";")).join("\n");

            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "diensten_lijst.csv");
            document.body.appendChild(link); // Required for FF

            link.click(); 
        },

        validateColumns(){

            var result = true;

            if (this.cProcedure == '1') {
                this.data.validation_configuration = false;
                return result;
            }

            var types = [];
            this.data.columns.items.forEach(element => {
                if(element.type){
                    types.push(element.type.value);
                }
            });
            
            if(types.length == 0) {
                this.data.validation_configuration = true;
                return 'nofile';
            }
            
            if(this.data.client_department == null && !types.includes(1)) {
                this.data.validation_configuration = true;
                return false;
            }

            if(!types.includes(2)){ 
                this.data.validation_configuration = true;
                return false; // function
            }

            if ((types.includes(4) && types.includes(6) && types.includes(7)) ||  // date / hour from / hour to
                (types.includes(4) && types.includes(8)) || // date / hour from to
                (types.includes(5) && types.includes(7)) ||
                (types.includes(5) && types.includes(9))) { 
                    this.data.validation_configuration = false;
                    return true;   // date and hour from / hour to
            }else { 
                this.data.validation_configuration = true;
                return false; 
            }

        }
    },
 
};
</script>