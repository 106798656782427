<template>
  <b-card
    no-body
    :class="{ open: visible }"
    @mouseenter="collapseOpen"
    @mouseleave="collapseClose"
  >
    <b-card-header
      :class="{ collapsed: !visible }"
      :aria-expanded="visible ? 'true' : 'false'"
      :aria-controls="collapseItemID"
      role="tab"
      data-toggle="collapse"
      @click="updateVisible(!visible)"
    >
      <slot name="header">
        <span class="lead collapse-title">{{ title }}</span>
      </slot>
    </b-card-header>

    <b-collapse
      :id="collapseItemID"
      v-model="visible"
      :accordion="accordion"
      role="tabpanel"
    >
      <b-card-body>
        <slot />
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
export default {
    props: {
        isVisible: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            required: false
        },
        id: {
            type: String,
            default: "item"
        },
        open: {
            type: Boolean,
            default: false
        }
    },

    created() {
      this.collapseItemID = "collapse-" + this.id;
      this.visible = this.isVisible;
    },

    watch: {
      isVisible: function(val) {     
        if (this.isVisible !== this.visible) {
          this.visible = val;
          this.updateVisible(val);
        }
      }
    },

    computed: {
        accordion() {
          return this.$parent.accordion
            ? `accordion-${this.$parent.collapseID}`
            : null;
        }
    },

    data() {
      return {
        visible: false,
        collapseItemID: "",
        openOnHover: this.$parent.hover
      };
    },
    
    methods: {
      updateVisible(val = true) {
        
        this.visible = val;
        this.$emit("update:isVisible", val);
      },
      collapseOpen() {
        if (this.openOnHover) this.updateVisible(true);
      },
      collapseClose() {
        if (this.openOnHover) this.updateVisible(false);
      }
    }
};
</script>
