<template>
    <b-modal ref="modal-edit" v-model="computedShow" no-close-on-backdrop :title="trans('sh-add-aliases',314)">
        <ValidationObserver ref="form" >
            <b-form @submit.prevent="onSubmit">

                <app-row-left-label :label="trans('aliases',314)">
                   <app-tags v-model="cArgs.name_aliases" @update:error="tags_error = $event"/>
                </app-row-left-label>

            </b-form>
        </ValidationObserver>

        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',282)}}</app-button>
            <app-button type="primary" @click="changeType" :loading="loading"  :disabled="tags_error === undefined || tags_error">
                {{trans('save',282)}}
            </app-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";
import AppTags from '@core/components/AppTags.vue';

export default {
    components: {
        ValidationObserver,
        AppTags
    },

    props: ["show", "result", "args", "return"],

    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },
        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },
        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        }
    },

    data() {
        return {
            loading: false,
            tag_name:null,
            tags_error: true
        };
    },

    methods: {
        tagValidator(tag) {
            if(tag.length > 60){
                return false;
            }else{
                return true;
            }
        },
    
        changeType() {

            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return false;
                }else{
                    this.loading = true;
                    axios
                        .post("shifts/changeTags", {
                            id_client_data_main: this.cArgs.id_client_data_main,
                            id_qualification_const_type: this.cArgs.id_qualification_const_type,
                            name_alias: this.cArgs.name_aliases,
                            filters: JSON.stringify(this.cArgs.filters)
                        })
                        .then((res) => {
                            this.$emit("update:show", false);
                            this.$emit("update:result", 1);

                            for(var i=0; i<res.data.items.list.length; i++){
                                if(typeof res.data.items.list[i].name_aliases === 'string' && res.data.items.list[i].name_aliases !== '' && res.data.items.list[i].name_aliases !== null){
                                    res.data.items.list[i].name_aliases = res.data.items.list[i].name_aliases.split(',');
                                }
                            }

                            this.cReturn =  res.data.items;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        }).finally(() => {
                            this.loading = false;
                        });
                    }
            })
        },
        
        closeModal() {
            this.$emit("update:show", false);
        },
    },
};
</script>

<style scoped></style>
