<template>
    <div>
        <app-input v-model="qualifications.qualification_names" @click="searchQualifications()" :disabled="disabled" readonly/>
        <offers-shifts-add-bulk-qualifications-change v-if="mQualificationChange.show" :show.sync="mQualificationChange.show" :result.sync="mQualificationChange.result" :args.sync="mQualificationChange.args"/>
    </div>
</template>
<script>


import axios from "@axios";
import OffersShiftsAddBulkQualificationsChange from './OffersShiftsAddBulkQualificationsChange.vue'

export default {

    components: {
        OffersShiftsAddBulkQualificationsChange,
    },

    props: {
            qualifications: {},
            disabled: { type: Boolean, default: false },  
            checkQualification: {},
            item: {},
            idClientDataMain: {}        
    },

    computed: {
        cQualifications: {
            get() { return this.qualifications },
            set(value) { this.$emit('update:qualifications', value) }
        },

        cCheckQualification: {
            get() { return this.checkQualification },
            set(value) { this.$emit('update:checkQualification', value) }
        },
    },

    watch: {
        "mQualificationChange.result": {
             handler: function(val) {
                if(this.mQualificationChange.result == 1){
                    this.cQualifications.qualification_names = this.mQualificationChange.args.qualification_names;
                    this.cQualifications.ids_qualification_const_type = this.mQualificationChange.args.ids_qualification_const_type; 
                    this.cQualifications.qualification_object = this.mQualificationChange.args.qualification_object;
                    this.cQualifications.non_matched_aliases = this.mQualificationChange.args.non_matched_aliases;
                    this.mQualificationChange.result = 0;     
                }
            },
            deep:true
        },

        "mQualificationChange.show": {
             handler: function(val) {
                if(this.mQualificationChange.show == false){
                    this.cQualifications.qualification_error = 0; 
                    this.cCheckQualification = this.item.id_temp;
                }
            },
            deep:true
        },
    },

    data() {
        return {
            mQualificationChange: { show: false, args: {
                ids_qualification_const_type:null,
                ids_qualification_const_type_querry:null,
                qualification_names:null,
                qualification_object: null,
                non_matched_aliases: null
            }, result: 0, return: null },
        };
    },

    methods: {
        searchQualifications() {
            if(this.qualifications.qualification_object.length > 0){
                this.mQualificationChange.args.id_client_data_main = this.idClientDataMain;
                this.mQualificationChange.args.ids_qualification_const_type = this.qualifications.ids_qualification_const_type;
                this.mQualificationChange.args.ids_qualification_const_type_querry = JSON.parse(JSON.stringify(this.qualifications.ids_qualification_const_type));
                this.mQualificationChange.args.qualification_names = this.qualifications.qualification_names;
                this.mQualificationChange.args.qualification_object = JSON.parse(JSON.stringify(this.qualifications.qualification_object));
                this.mQualificationChange.args.non_matched_aliases = this.qualifications.non_matched_aliases;
                this.mQualificationChange.show = true;
            }
        },
        
    }

    };
</script>

<style scoped>
.input-group-text {
    padding: 0px 10px !important
}
.custom-search{
    cursor: pointer;
}

.custom-search:hover{
    color: rgba(0,0,0,.8) !important;
}

</style>